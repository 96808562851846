import IconoAlerta from '../../imagenes/alerta.svg'

const iconos = {
  warning: IconoAlerta,
}

export default function ComponenteAlerta({
  mensaje,
  icono = 'warning',
  mensajeClassNames = '',
  contenedorPrincipal = 'w-100%',
  background = 'bg-cendiatra-gris-5',
  colorMensaje = 'text-cendiatra-semaforo-rojo',
  iconoClassNames = 'w-5 h-5',
}) {
  return (
    <div className={contenedorPrincipal}>
      <div
        className={`w-full flex justify-center items-center ${background} py-2 mb-1 rounded-lg`}
      >
        <div className="w-1/12 flex justify-center items-center mx-4">
          <img
            src={iconos[icono]}
            className={iconoClassNames}
            alt="alerta"
            style={{width: '100%'}}
          />
        </div>
        <div className="w-11/12 pr-1">
          <span
            className={`${colorMensaje} font-bold text-13px ${mensajeClassNames}`}
          >
            {mensaje}
          </span>
        </div>
      </div>
    </div>
  )
}
