import {getRestApi} from './ApiAxios'

export async function ObtenerTiqueterasPendientesGestion(payload = {}) {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/FachadaObtenerTiqueterasPendientesDeGestion',
    payload
  )
}

export async function GuardarControlTiquetera(
  payload = {
    detallePagoId: 0,
    numeroLibreta: 0,
    consecutivoInicial: '',
    consecutivoFinal: '',
    responsableEntrega: '',
  }
) {
  const apiReq = await getRestApi()
  return await apiReq.put('gato-api-function/GuardarControlTiquetera', payload)
}

export async function ObtenerTiqueterasGestionadas(payload = {}) {
  const apiReq = await getRestApi()
  return await apiReq.post(
    'gato-api-function/FachadaObtenerTiqueterasGestionadas',
    payload
  )
}

export async function FachadaObtenerInformeTiqueterasGestionadas(payload) {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/FachadaObtenerInformeTiqueterasGestionadas',
    {params: payload}
  )
}

export async function FachadaObtenerInformeTiqueterasPendientesDeGestion(
  payload
) {
  const apiReq = await getRestApi()
  return await apiReq.get(
    'gato-api-function/FachadaObtenerInformeTiqueterasPendientesDeGestion',
    {params: payload}
  )
}
