export const TIPOS_RETENCIONES = {
  RETENCION_RENTA: 1,
  RETENCION_ICA: 2,
  SOBRETASA_BOMBERIL: 3,
  RETENCION_IVA: 4,
}

export const TIPOS_RETENCION_STRING = {
  1: 'RETENCIÓN DE RENTA',
  2: 'RETENCIÓN DE ICA',
  3: 'SOBRETASA BOMBERIL',
  4: 'RETENCIÓN IVA',
}
