import React, {useContext, useEffect, useState} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {ListaDesplegable, Input} from '../../inputs'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import {
  ObtenerDepartamentosPorNombre,
  ObtenerDepartamentoPorCiudad,
  ObtenerCiudadesPorDepartamento,
  ObtenerLocalidadPorNombre,
} from '../../../cache/servicios-cache'
import {TEXTO_VISUAL, regexCorreoElectronico} from '../../../constantes'
import {regexBasicoV1} from '../../../constantes/regex'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../../utilidades/window.utils'

const InformacionDireccion = ({
  numeroPagina,
  deshabilitarEdicion = false,
  modoEdicion = false,
  esModificacionUsuario = false,
}) => {
  const {
    register,
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    watch,
    control,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
  })
  const {accounts} = useMsal()
  const rol = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''

  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)

  const NombreCiudad = fnObtenerInformacionSessionOLocalStorage(
    'nombreCiudad',
    rol
  )

  const [departamentosFiltrados, setDepartamentosFiltrados] = useState([])
  const [municipiosFiltrados, setMunicipiosFiltrados] = useState([])
  const [localidadesFiltradas, setLocalidadesFiltradas] = useState([])

  const [opcionesPais, setOpcionesPais] = useState([])

  const countryCodes = require('country-codes-list')

  const codigoDePaises = countryCodes.customList(
    'countryCode',
    '+{countryCallingCode}'
  )

  const [paisesArray, setPaisesArray] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setOpcionesPais(codigoDePaises)
    }

    fetchData()
  }, [])

  useEffect(() => {
    const nuevoArray = []

    const nombresUnicos = [...new Set(Object.values(opcionesPais))]

    nombresUnicos.sort()

    nombresUnicos.forEach((nombre) => {
      const id = Object.keys(opcionesPais).find(
        (key) => opcionesPais[key] === nombre
      )
      nuevoArray.push({
        Id: opcionesPais[id],
        Nombre: nombre,
      })
    })

    setPaisesArray(nuevoArray)
  }, [opcionesPais])

  useEffect(() => {
    if (deshabilitarEdicion || esModificacionUsuario) {
      return
    }
    ObtenerDepartamentoPorCiudad(NombreCiudad).then((res) => {
      if (res) {
        contextoRegistro.setInformacionDireccion({
          ...contextoRegistro.informacionDireccion,
          departamento: res.Nombre,
        })
        setValue('departamento', res.Nombre)
      }
      setValue('paisResidencia', 'COLOMBIA')
      setValue('indicativoCelular', '+57')
      setValue('zona', 1)
      clearErrors('indicativoCelular')
    })
    trigger()
  }, [contextoRegistro.actualizar])

  useEffect(() => {
    if (numeroPagina == contextoRegistro.numeroPagina) {
      trigger()
      contextoRegistro.setformularioActualTieneErrores(
        !isValid || Object.keys(errors).length != 0
      )
    }
    contextoRegistro.setErroresModificarFormulario({
      ...contextoRegistro.erroresModificarFormulario,
      direccion: !isValid || Object.keys(errors).length != 0,
    })
  }, [isValid, contextoRegistro.numeroPagina])

  useEffect(() => {
    const subscription = watch(() => {
      contextoRegistro.setInformacionDireccion(getValues())
      trigger()
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (
      contextoRegistro.usuarioId == '' &&
      contextoRegistro.modificarPacienteUsuarioId == '' &&
      !modoEdicion
    ) {
      return
    }
    reset(contextoRegistro.informacionDireccion)
    trigger()
  }, [contextoRegistro.actualizar])

  const manejarDepartamentoCambiado = (e) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      departamento: e.target.value,
    })
    setError('departamento', {type: 'require', message: ''})
    setValue('departamento')
    if (e.target.value.length >= 3) {
      ObtenerDepartamentosPorNombre(e.target.value).then((res) => {
        if (res) {
          setDepartamentosFiltrados(
            res.map((departamento) => ({
              id: departamento.Id,
              filtro: departamento.Nombre,
            }))
          )
        }
      })
    } else {
      setDepartamentosFiltrados([])

      contextoRegistro.setInformacionDireccion({
        ...contextoRegistro.informacionDireccion,
        departamento: e.target.value,
        municipio: '',
      })
      setMunicipiosFiltrados([])
      setError('municipio', {type: 'require', message: ''})
      setValue('municipio')
    }
  }

  const manejarDepartamentoSeleccionado = (departamentoSeleccionado) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      departamento: departamentoSeleccionado.filtro,
    })
    setValue('departamento', departamentoSeleccionado.filtro)
    clearErrors('departamento')
    setDepartamentosFiltrados([])
  }

  const manejarMunicipioCambiado = (e) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      municipio: e.target.value,
    })
    setError('municipio', {type: 'require', message: ''})
    setValue('municipio')
    if (e.target.value.length >= 3) {
      ObtenerCiudadesPorDepartamento(
        e.target.value,
        contextoRegistro.informacionDireccion.departamento
      ).then((res) => {
        if (res) {
          setMunicipiosFiltrados(
            res.map((municipio) => ({
              id: municipio.Id,
              filtro: municipio.Nombre,
            }))
          )
        }
      })
    } else {
      setMunicipiosFiltrados([])
    }
  }

  const manejarMunicipioSeleccionado = (municipioSeleccionado) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      municipio: municipioSeleccionado.filtro,
    })
    setValue('municipio', municipioSeleccionado.filtro)
    clearErrors('municipio')
    setDepartamentosFiltrados([])
  }

  const manejarLocalidadCambiada = (e) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      localidad: e.target.value,
    })
    setError('localidad', {type: 'require', message: ''})
    setValue('localidad')
    if (e.target.value.length >= 3) {
      ObtenerLocalidadPorNombre(e.target.value).then((res) => {
        if (res) {
          setLocalidadesFiltradas(
            res.map((municipio) => ({
              id: municipio.Id,
              filtro: municipio.Nombre.toUpperCase(),
            }))
          )
        }
      })
    } else {
      setLocalidadesFiltradas([])
    }
  }

  const manejarLocalidadSeleccionada = (localidadSeleccionado) => {
    contextoRegistro.setInformacionDireccion({
      ...contextoRegistro.informacionDireccion,
      localidad: localidadSeleccionado.filtro,
    })
    setValue('localidad', localidadSeleccionado.filtro)
    clearErrors('localidad')
    setDepartamentosFiltrados([])
  }

  return (
    <div
      className={`w-full flex flex-wrap justify-between items-center my-2 ${
        deshabilitarEdicion ? 'pointer-events-none' : ''
      }`}
    >
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="paisResidencia"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'paisResidencia'
                  )
                    ? 'appearance-none bg-cendiatra-gris-placeholder pointer-events-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-white  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none bg-cendiatra-gris-placeholder pointer-events-none rounded relative block w-full  p-1.5 border border-cendiatra text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'País de residencia*'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setInformacionDireccion(getValues())
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.paisResidencia?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="departamento"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-31%'}
              titulo={'Departamento*'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'departamento'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'text'}
              placeholder={'Descripción'}
              valorDelCampoFiltro={
                contextoRegistro.informacionDireccion.departamento
              }
              desactivarOtroFiltro={''}
              informacionFiltrada={departamentosFiltrados}
              handleChange={manejarDepartamentoCambiado}
              handleOptionChange={manejarDepartamentoSeleccionado}
            />
          )}
        />

        <Controller
          name="municipio"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <CampoFiltrarEspañol
              titulo={'Municipio*'}
              estilosPersonalizados={'w-31%'}
              textoTitulo={'text-sm font-medium'}
              estilosInput={
                Object.keys(errors).find((element) => element === 'municipio')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              placeholder={'Autocompletar'}
              tipo={'text'}
              valorDelCampoFiltro={
                contextoRegistro.informacionDireccion.municipio
              }
              desactivarOtroFiltro={''}
              informacionFiltrada={municipiosFiltrados}
              handleChange={manejarMunicipioCambiado}
              handleOptionChange={manejarMunicipioSeleccionado}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-between items-center my-2">
        <Controller
          name="localidad"
          control={control}
          rules={{
            required: false,
          }}
          render={() => (
            <CampoFiltrarEspañol
              titulo={'Localidad'}
              estilosPersonalizados={'w-31%'}
              textoTitulo={'text-sm font-medium'}
              estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
              placeholder={'Autocompletar'}
              tipo={'text'}
              valorDelCampoFiltro={
                contextoRegistro.informacionDireccion.localidad
              }
              desactivarOtroFiltro={
                contextoRegistro.informacionDireccion.municipio != 'BOGOTÁ'
              }
              informacionFiltrada={localidadesFiltradas}
              handleChange={manejarLocalidadCambiada}
              handleOptionChange={manejarLocalidadSeleccionada}
            />
          )}
        />
        <Controller
          name="zona"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Zona'}
              opciones={contextoAplicacion.zonas}
              valor={value}
              onBlur={() =>
                contextoRegistro.setInformacionDireccion(getValues())
              }
            />
          )}
        />
        <Controller
          name="direccion"
          control={control}
          rules={{
            required: false,
            pattern: /^[a-zA-Z0-9-ZñÑáéíóúÁÉÍÓÚ/\.,#\[/\]/)(+-//\s]+$/,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'direccion')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Dirección'}
                valor={value}
                onBlur={() =>
                  contextoRegistro.setInformacionDireccion(getValues())
                }
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.direccion?.type === 'pattern' &&
                  'Se permiten estos caracteres + ( ) [ ] , / - . #'}
              </span>
            </div>
          )}
        />
      </div>
      <div className="w-full flex justify-between items-center mt-2 mb-50px">
        <div className="w-31% flex justify-between items-center">
          <div className="w-5/12">
            <Controller
              name="estrato"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-11/12'}
                  estilosLista={
                    Object.keys(errors).find((element) => element === 'estrato')
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  titulo={'Estrato*'}
                  opciones={contextoAplicacion.estratos}
                  valor={value}
                  onBlur={() =>
                    contextoRegistro.setInformacionDireccion(getValues())
                  }
                />
              )}
            />
          </div>
          <div className="w-7/12">
            <Controller
              name="telefono"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'telefono'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'number'}
                  placeholder={'Descripción'}
                  titulo={'Teléfono*'}
                  valor={value}
                  onBlur={() =>
                    contextoRegistro.setInformacionDireccion(getValues())
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="w-31% flex justify-between items-center">
          <div className="w-5/12">
            <Controller
              name="indicativoCelular"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <ListaDesplegable
                  onChange={onChange}
                  estilosContenedor={'w-11/12'}
                  estilosLista={
                    Object.keys(errors).find(
                      (element) => element === 'indicativoCelular'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  titulo={'Celular*'}
                  opciones={paisesArray}
                  valor={value}
                  onBlur={() =>
                    contextoRegistro.setInformacionDireccion(getValues())
                  }
                />
              )}
            />
          </div>
          <div className="w-7/12">
            <Controller
              name="celular"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full mt-1'}
                  estilosInput={
                    Object.keys(errors).find((element) => element === 'celular')
                      ? 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm mt-6 '
                      : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm mt-6'
                  }
                  tipo={'number'}
                  placeholder={'Descripción'}
                  titulo={''}
                  valor={value}
                  onBlur={() =>
                    contextoRegistro.setInformacionDireccion(getValues())
                  }
                />
              )}
            />
          </div>
        </div>
        <div className="w-31%">
          <Controller
            name="correoElectronico"
            control={control}
            rules={{
              required: true,
              pattern: regexCorreoElectronico,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-full'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'correoElectronico'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                  }
                  tipo={'email'}
                  placeholder={'Descripción'}
                  titulo={'Correo electrónico*'}
                  valor={value}
                  onBlur={() =>
                    contextoRegistro.setInformacionDireccion(getValues())
                  }
                />
                <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                  {errors.correoElectronico?.type === 'pattern' &&
                    'Ej: ejemplo@ejemplo.com'}
                </span>
              </>
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default InformacionDireccion
