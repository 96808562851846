import React, {useContext, useEffect, useRef, useState} from 'react'
import {useForm, Controller, set} from 'react-hook-form'
import moment from 'moment'
import {ContextoRegistro, ContextApplication} from '../../../contexto'
import {Input, AreaDeTexto, ListaDesplegable, ErroresInput} from '../../inputs'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import IconoAlerta from '../../../imagenes/alerta.svg'
import CodigoQr from '../../../imagenes/codigoQR.png'
import {
  ObtenerTipoAtencionPorId,
  ObtenerEmpresasPorNitONombre,
  ObtenerEmpresasHijasPorIdPadre,
  ObtenerEmpresaPorId,
  ObtenerEmpresasYEmpresasHijasPorNombre,
  AgregarEmpresasACache,
  AgregarTarifariosACache,
  AgregarServiciosACache,
  AgregarPaquetesACache,
  ObtenerTiposDeDocumento,
  ObtenerGrupoSanguineoPorNombre,
  ObtenerDepartamentoPorId,
  ObtenerCiudadPorId,
  ObtenerLocalidadPorId,
  ObtenerArlPorId,
  ObtenerEpsPorId,
  ObtenerFondoDePensionPorId,
  ObtenerTipoDocumentoPorId,
} from '../../../cache/servicios-cache'
import {
  ObtenerTarifarioPorEmpresaId,
  ObtenerOrdenAbiertaPorUsuarioId,
  ObtenerUsuarioPorId,
  ObtenerUsuarioPorTipoDocumentoYDocumento,
  ObtenerInformacionPaciente,
  ObtenerInformacionCuentaPorEmpresaId,
  // ObtenerInformacionCuentaPorUsuarioId,
} from '../../../microservicios'
import {
  CLASIFICACION_SERVICIO,
  TEXTO_VISUAL,
  regexNombreEmpresaHC,
  regexSoloLetrasYNumeros,
  TIPO_ATENCION_REGISTRO,
  TIPOS_FACTURACION,
  DOCUMENTO,
  ALERTAS,
} from '../../../constantes'
import {
  regexBasicoV1,
  regexTextoCaracteresBasicos,
} from '../../../constantes/regex'
import {aplicarFormatoMoneda, obtenerFechaMinima} from '../../../utilidades'
import {AlertaDinamica, AlertaLongitudDocumento} from '../../MostrarInformacion'
import clsx from 'clsx'

const InformacionPersonal = ({
  numeroPagina,
  deshabilitarEdicion = false,
  modoEdicion = false,
}) => {
  const {
    trigger,
    setValue,
    clearErrors,
    setError,
    getValues,
    reset,
    control,
    watch,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const anioFechMinima = 99

  const contextoRegistro = useContext(ContextoRegistro)
  const contextoAplicacion = useContext(ContextApplication)
  const [empresasFiltradas, setEmpresasFiltradas] = useState([])
  const [tipoDocumento, setTipoDocumento] = useState([])
  const [numeroDocumento, setNumeroDocumento] = useState([])
  const [saldoParticular, setSaldoParticular] = useState(0)

  const [empresasHijasFiltradas, setEmpresasHijasFiltradas] = useState([])

  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')

  const [respuestaPaquetes, setRespuestaPaquetes] = useState([])
  const [respuestaServicios, setRespuestaServicios] = useState([])
  const [pattern, setPattern] = useState('')
  const [empresaHijaNoValida, setEmpresaHijaNoValida] = useState(false)

  const [ordenportal, setOrdenPortal] = useState(false)

  const [infoNumeroCaracteres, setInfoNumeroCaracteres] = useState({
    minimo: 6,
    maximo: 10,
    mensaje: '',
    tipoCampo: 'text',
  })

  const saldoDisponibleRef = useRef(0)
  const tipoDocumentoTransformado = useRef('')

  const fechaDeHoy = moment().format().slice(0, -15)

  const [fechaEsValida, setFechaEsValida] = useState(false)

  useEffect(() => {
    if (contextoRegistro.informacionPersonal.fechaNacimiento) {
      const fechaNacimiento = moment(
        contextoRegistro.informacionPersonal.fechaNacimiento
      )
      const fechaMinima = moment()
        .subtract(anioFechMinima, 'years')
        .startOf('day')
        .subtract(1, 'day')
      setFechaEsValida(!fechaNacimiento.isAfter(fechaMinima))
    }
  }, [contextoRegistro.informacionPersonal.fechaNacimiento])

  useEffect(async () => {
    setTipoDocumento(await ObtenerTiposDeDocumento())
    setTimeout(() => document.getElementById('primerInput').focus(), 1000)
  }, [])
  useEffect(async () => {
    await AgregarEmpresasACache()
    await AgregarTarifariosACache()
    await AgregarServiciosACache()
    await AgregarPaquetesACache()
    trigger()
  }, [])

  useEffect(() => {
    reset(contextoRegistro.informacionPersonal)
    trigger()
  }, [
    contextoRegistro.actualizar,
    contextoRegistro.informacionPersonal.tipoAtencion,
  ])

  useEffect(() => {
    trigger()
  }, [infoNumeroCaracteres])

  useEffect(() => {
    if (numeroPagina === contextoRegistro.numeroPagina) {
      trigger()
      contextoRegistro.setformularioActualTieneErrores(
        !isValid ||
          Object.keys(errors).length !== 0 ||
          empresaHijaNoValida ||
          fechaEsValida
      )
    }
    setValue('empresaNit', contextoRegistro.empresaSeleccionada.nombre)
  }, [
    isValid,
    contextoRegistro.numeroPagina,
    empresaHijaNoValida,
    fechaEsValida,
  ])

  useEffect(() => {
    const subscription = watch(() => {
      contextoRegistro.setInformacionPersonal(getValues())
      trigger()
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const manejarTipoAtencion = async (e) => {
    if (e.target.value == 2) {
      setError('empresaNit', {type: 'require', message: ''})
      setValue('empresaNit')
      trigger()
    }

    if (e.target.value == '') {
      setValue('tipoAtencion')
      setError('tipoAtencion', {type: 'require', message: ''})
      setError('empresaNit', {type: 'require', message: ''})
      setValue('empresaNit', '')
      contextoRegistro.setEmpresaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        tipoAtencion: '',
        observaciones: '',
        tipoFacturacion: '',
        estaActiva: '',
        motivoDesactivacion: '',
      })
      contextoRegistro.setEmpresaHijaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        observaciones: '',
        estaActiva: '',
        motivoDesactivacion: '',
      })
      contextoRegistro.setTarifarioRegistro('')
      contextoRegistro.setExamenesSeleccionados([])
      contextoRegistro.setTodosLosExamenes([])
      setEmpresasFiltradas([])
      setEmpresasHijasFiltradas([])
    } else {
      setValue('tipoAtencion', e.target.value)
      setValue('empresaNit', 'PARTICULAR')
      clearErrors('tipoAtencion')
      contextoRegistro.setTarifarioRegistro('')
      contextoRegistro.setEmpresaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        tipoAtencion: '',
        observaciones: '',
        tipoFacturacion: '',
        estaActiva: '',
        motivoDesactivacion: '',
      })
      contextoRegistro.setEmpresaHijaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        observaciones: '',
      })
      contextoRegistro.setExamenesSeleccionados([])
      contextoRegistro.setTodosLosExamenes([])
      setEmpresasFiltradas([])
      setEmpresasHijasFiltradas([])
      ObtenerTipoAtencionPorId(JSON.parse(e.target.value)).then((res) => {
        if (
          res.Nombre.toUpperCase() === TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
        ) {
          ObtenerEmpresasYEmpresasHijasPorNombre(res.Nombre).then(
            async (empresa) => {
              contextoRegistro.setEmpresaSeleccionada({
                ...contextoRegistro.empresaSeleccionada,
                empresaPadreId: empresa[0].EmpresaPadreId,
                id: empresa[0].Id,
                nombre: `${empresa[0].Nombre.toUpperCase()}-${empresa[0].Nit}`,
                nit: empresa[0].Nit,
                tipoAtencion: TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR,
                observaciones: empresa[0].Observaciones,
                tipoFacturacion: empresa[0].TipoFacturacion.toUpperCase(),
                estaActiva: empresa.EstaActiva,
                motivoDesactivacion: empresa.MotivoDesactivacion,
              })
              setValue('empresaNit', empresa[0].Nombre)
              clearErrors('empresaNit')
              contextoRegistro.setTarifarioRegistro(
                (await ObtenerTarifarioPorEmpresaId(empresa[0].Id)).data
              )
            }
          )
        }
      })
    }
  }

  const validarExisteEmpresa = (e) => {
    return Boolean(
      e.length > 3 && contextoRegistro?.empresaSeleccionada?.id !== ''
    )
  }

  const manejarEmpresaCambiada = async (e) => {
    if (e.target.value == '') {
      contextoRegistro.setEmpresaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        tipoAtencion: '',
        observaciones: '',
        tipoFacturacion: '',
        estaActiva: '',
        motivoDesactivacion: '',
      })
      setValue('empresaHijaNit', '')
    } else {
      contextoRegistro.setEmpresaSeleccionada({
        ...contextoRegistro.empresaSeleccionada,
        nombre: e.target.value,
      })
    }
    setError('empresaNit', {type: 'require', message: ''})
    setValue('empresaNit')
    let empresas
    if (e.target.value.length >= 3) {
      contextoRegistro.setSedeNoExiste(false)
      if (isNaN(e.target.value)) {
        empresas = await ObtenerEmpresasPorNitONombre('', e.target.value)
      } else {
        empresas = await ObtenerEmpresasPorNitONombre(e.target.value, '')
      }

      setEmpresasFiltradas(
        empresas.map((empresa) => ({
          id: empresa.Id,
          filtro: empresa.Nit + '-' + empresa.Nombre.toUpperCase(),
          empresaPadreId: empresa.EmpresaPadreId,
          nit: empresa.Nit,
          observaciones: empresa.Observaciones,
          tipoFacturacion: empresa.TipoFacturacion.toUpperCase(),
          estaActiva: empresa.EstaActiva,
          motivoDesactivacion: empresa.MotivoDesactivacion,
        }))
      )
    } else {
      contextoRegistro.setEmpresaSeleccionada((prev) => {
        return {
          ...prev,
          id: '',
        }
      })
      setEmpresasFiltradas([])
      setEmpresasHijasFiltradas([])
      contextoRegistro.setMediosDePagoSeleccionados([])
      contextoRegistro.setEmpresaHijaSeleccionada({
        id: '',
        nombre: '',
        empresaPadreId: '',
        nit: '',
        observaciones: '',
        estaActiva: '',
        motivoDesactivacion: '',
      })
      contextoRegistro.setSedeNoExiste(false)
    }
  }

  const manejarEmpresaSeleccionada = async (empresaSeleccionada) => {
    if (!empresaSeleccionada.estaActiva) {
      modalEmpresaInactiva(empresaSeleccionada.motivoDesactivacion)
      return
    }

    contextoRegistro.setEmpresaSeleccionada({
      ...contextoRegistro.empresaSeleccionada,
      empresaPadreId: empresaSeleccionada.empresaPadreId,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
      observaciones: empresaSeleccionada.observaciones,
      tipoFacturacion: empresaSeleccionada.tipoFacturacion.toUpperCase(),
      estaActiva: empresaSeleccionada.EstaActiva,
      motivoDesactivacion: empresaSeleccionada.MotivoDesactivacion,
    })
    setValue('empresaNit', empresaSeleccionada.filtro)
    clearErrors('empresaNit')
    setEmpresasFiltradas([])
    contextoRegistro?.setEmpresasHijas(
      await ObtenerEmpresasHijasPorIdPadre(empresaSeleccionada.id, true)
    )
    contextoRegistro.setTarifarioRegistro(
      (await ObtenerTarifarioPorEmpresaId(empresaSeleccionada.id)).data
    )
  }

  const manejarEmpresaHija = async (e) => {
    contextoRegistro.setEmpresaHijaSeleccionada({
      ...contextoRegistro.empresaHijaSeleccionada,
      nombre: e.target.value,
    })
    if (e.target.value.length >= 3) {
      const filtro = contextoRegistro?.empresasHijas.filter(
        ({razonSocial}) =>
          razonSocial.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
      )
      const filtroCodigo = contextoRegistro?.empresasHijas.filter(
        ({nit}) => nit.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
      )
      const empresas = filtro.concat(filtroCodigo)
      setEmpresasHijasFiltradas(
        empresas.map((empresaHija) => ({
          id: empresaHija.id,
          filtro:
            empresaHija.nit + ' - ' + empresaHija.razonSocial.toUpperCase(),
          nit: empresaHija.nit,
          observaciones: empresaHija.observaciones,
        }))
      )

      if (empresas.length === 0) {
        setEmpresaHijaNoValida(true)
      }
    } else {
      setEmpresasHijasFiltradas([])
      contextoRegistro.setEmpresaHijaSeleccionada({
        id: '',
        empresaPadreId: '',
        nit: '',
        observaciones: '',
      })
      setEmpresaHijaNoValida(false)
      setValue('empresaHijaNit', '')
    }
  }
  const manejarEmpresaHijaSeleccionada = async (empresaSeleccionada) => {
    contextoRegistro.setEmpresaHijaSeleccionada({
      ...contextoRegistro.empresaSeleccionada,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
      observaciones: empresaSeleccionada.observaciones,
      estaActiva: empresaSeleccionada.EstaActiva,
      motivoDesactivacion: empresaSeleccionada.MotivoDesactivacion,
    })
    setValue('empresaHijaNit', empresaSeleccionada.filtro)
    clearErrors('empresaHijaNit')
    setEmpresasHijasFiltradas([])
  }
  const manejarLectorDeCodigo = async (e, tabIndex) => {
    if (tabIndex == 2) {
      contextoAplicacion.setIsLoading(true)
      setApellidos(e.target.value?.trim() ?? '')
      contextoRegistro.setTipoDocumentoId(1)
      setValue('tipoDocumento', 1)
      clearErrors('tipoDocumento')
    }
    if (tabIndex == 3) {
      if (e.target.value) setApellidos(...(apellidos + e.target.value))
      setValue(
        'apellidos',
        (apellidos + ' ' + e.target.value).trim().toUpperCase()
      )
      clearErrors('apellidos')
    }
    if (tabIndex == 4) {
      setNombres(e.target.value?.trim() ?? '')
    }
    if (tabIndex == 5) {
      setApellidos(...(nombres + e.target.value))
      setValue('nombre', (nombres + ' ' + e.target.value).trim().toUpperCase())
      clearErrors('nombre')
    }
    if (tabIndex == 6) {
      if (e.target.value == 'F') {
        setValue('sexo', 1)
        clearErrors('sexo')
      } else {
        setValue('sexo', 2)
        clearErrors('sexo')
      }
    }
    if (tabIndex == 7) {
      let fechaModificada =
        e.target.value.slice(4, 8) +
        '-' +
        e.target.value.slice(2, 4) +
        '-' +
        e.target.value.slice(0, 2)
      let fechaNacimiento = moment(fechaModificada).format('YYYY-MM-DD')
      setValue('fechaNacimiento', fechaNacimiento)
      clearErrors('fechaNacimiento')
    }
    if (tabIndex == 8) {
      if (e.target.value.length > 1) {
        const grupoSanguineo = await ObtenerGrupoSanguineoPorNombre(
          e.target.value
        )
        setValue('grupoSanguineo', grupoSanguineo[0].Id)
        document.getElementById('primerInput').focus()
        contextoAplicacion.setIsLoading(false)
      }
    }
  }

  const manejaCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const modalEmpresaInactiva = (motivoDesactivacion) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <>
          <span>LA EMPRESA SELECCIONADA SE ENCUENTRA INACTIVA</span>
          <br />
          <br />
          <span>MOTIVO: {motivoDesactivacion}</span>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: limpiarEmpresa,
        },
      ],
    })
  }

  const limpiarEmpresa = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })

    setValue('empresaNit', '')

    contextoRegistro.setEmpresaSeleccionada({
      ...contextoRegistro.empresaSeleccionada,
      nombre: '',
    })
    setEmpresasHijasFiltradas([])
    setEmpresasFiltradas([])
  }

  useEffect(() => {
    if (contextoRegistro.turno && contextoRegistro.turno.usuarioId) {
      ObtenerUsuarioPorId(contextoRegistro.turno.usuarioId)
        .then((res) => {
          contextoRegistro.setNumeroDocumento(res.data.numeroDocumento)
          setValue('numeroDocumento', res.data.numeroDocumento)
          setNumeroDocumento(res.data.numeroDocumento)
          clearErrors('numeroDocumento')

          contextoRegistro.setTipoDocumentoId(res.data.tipoDocumento)
          setValue('tipoDocumento', res.data.tipoDocumento)
          clearErrors('tipoDocumento')

          trigger()
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [contextoRegistro.turno])

  useEffect(() => {
    if (contextoRegistro.numeroDocumento.length > 3) {
      if (
        contextoRegistro.numeroDocumento &&
        contextoRegistro.tipoDocumentoId
      ) {
        ObtenerUsuarioPorTipoDocumentoYDocumento(
          contextoRegistro.numeroDocumento,
          contextoRegistro.tipoDocumentoId
        )
          .then((res) => {
            const {data, status} = res

            if (status === 200) {
              contextoRegistro.setUsuarioExiste(true)
              contextoRegistro.setInformacionPersonal(
                (informacionPersonalActual) => {
                  return {
                    numeroDocumento: data.numeroDocumento,
                    tipoDocumento: data.tipoDocumento,
                    apellidos: data.apellidos,
                    nombre: data.nombres,
                    grupoSanguineo: data.grupoSanguineoId,
                    fechaNacimiento: data.fechaNacimiento
                      ? moment(data.fechaNacimiento).format('YYYY-MM-DD')
                      : '',
                    sexo: data.sexoId,
                    estadoCivil:
                      data.estadoCivilId ||
                      informacionPersonalActual.estadoCivil,
                    lugarNacimiento: data.lugarNacimiento,
                    escolaridad:
                      data.escolaridadId ||
                      informacionPersonalActual.escolaridad,
                    nacionalidad: data.nacionalidad,
                    tipoAtencion: informacionPersonalActual.tipoAtencion,
                  }
                }
              )

              contextoRegistro.setInformacionGeneral({
                ...contextoRegistro.informacionGeneral,
                raza: data.razaId,
                tipoPoblacion: data?.tipoPoblacionId,
              })
              contextoRegistro.setInformacionDireccion({
                ...contextoRegistro.informacionDireccion,
                celular: data.celular,
                indicativoCelular: data.indicativo ?? '',
                correoElectronico: data.correo,
              })
              contextoRegistro.setUsuarioId(data.id)
              trigger()
            }
            if (status === 204) {
              contextoRegistro.setDescontarSaldoPortal(false)
              contextoRegistro.setUsuarioExiste(false)
              trigger()
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [contextoRegistro.numeroDocumento, contextoRegistro.tipoDocumentoId])

  const modalOrdenConEmpresaDesactivada = (empresa) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <>
          <span className="font-bold">
            EL PACIENTE TIENE UNA ORDEN DE SERVICIO
          </span>
          <br />
          <span className="block">ASIGNADA POR LA EMPRESA:</span>
          <br />
        </>
      ),
      subtitulo: (
        <span className="font-bold">{empresa.Nombre.toUpperCase()}</span>
      ),
      contenido: (
        <>
          <div className="w-full flex justify-center items-center flex-wrap text-cendiatra -mt-5">
            <span className="w-full text-cendiatra text-lg mt-3">
              NO SE PUEDE CONTINUAR CON EL REGISTRO
            </span>
            <span className="w-full text-cendiatra text-lg">
              LA EMPRESA ESTÁ
              <strong> INACTIVA </strong>
              POR:
            </span>
            <span className="w-full text-cendiatra text-lg font-bold mt-3">
              {empresa?.MotivoDesactivacion}
            </span>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  const obtenerTipoDocumento = async () => {
    try {
      const tipoDocumentoId = parseInt(
        `${contextoRegistro.tipoDocumentoId ?? ''}`
      )
      if (isNaN(tipoDocumentoId)) {
        return
      }
      const data = await ObtenerTipoDocumentoPorId(tipoDocumentoId)
      tipoDocumentoTransformado.current = DOCUMENTO[data.Nombre]
    } catch (error) {
      console.error('Error al obtener el tipo de documento:', error)
    }
  }

  useEffect(() => {
    if (contextoRegistro.tipoDocumentoId) {
      obtenerTipoDocumento()
    }
  }, [contextoRegistro.tipoDocumentoId])

  useEffect(() => {
    if (Boolean(contextoRegistro.usuarioId)) {
      ObtenerOrdenAbiertaPorUsuarioId(contextoRegistro.usuarioId)
        .then(async (res) => {
          if (res.status == 200 && !modoEdicion) {
            setOrdenPortal(true)
            contextoRegistro.setInformacionOrdenServicio({
              ...contextoRegistro.informacionOrdenServicio,
              numeroOrden: res.data.id,
              tipoExamen: res.data.tipoExamenId,
              cargo: res.data.usuario.cargo,
            })
            contextoRegistro.setInformacionPersonal({
              ...contextoRegistro.informacionPersonal,
              tipoAtencion: TIPO_ATENCION_REGISTRO.EMPRESA,
            })

            const empresa = await ObtenerEmpresaPorId(res.data.empresaId)

            if (!empresa?.EstaActiva) {
              return modalOrdenConEmpresaDesactivada(empresa)
            }
            contextoRegistro.setCancelarValidacionServiciosYPaquetes(false)
            setRespuestaPaquetes(res.data.paquetes)
            setRespuestaServicios(res.data.servicios)

            let descontarSaldoPortalCliente = res.data.descontarSaldoPortal
            contextoRegistro.setDescontarSaldoPortal(
              res.data.descontarSaldoPortal
            )

            let datosEmpresaHija = null
            if (res.data.empresaHijaId > 0) {
              datosEmpresaHija = await ObtenerEmpresaPorId(
                res.data.empresaHijaId
              )
            }

            const itemEmpresaSeleccionada = {
              ...contextoRegistro.empresaSeleccionada,
              empresaPadreId: empresa.EmpresaPadreId,
              id: empresa.Id,
              nombre: empresa.Nombre,
              nit: empresa.Nit,
              observaciones: empresa.Observaciones,
              tipoFacturacion: empresa.TipoFacturacion.toUpperCase(),
              estaActiva: empresa.EstaActiva,
              motivoDesactivacion: empresa.MotivoDesactivacion,
            }

            contextoRegistro.setEmpresaSeleccionada({
              ...contextoRegistro.empresaSeleccionada,
              ...itemEmpresaSeleccionada,
            })

            if (datosEmpresaHija) {
              contextoRegistro.setEmpresaHijaSeleccionada({
                ...itemEmpresaSeleccionada,
                id: datosEmpresaHija.Id,
                nombre: datosEmpresaHija?.Nombre ?? 'Servers',
                nit: datosEmpresaHija.Nit,
                observaciones: datosEmpresaHija.Observaciones,
                estaActiva: datosEmpresaHija.EstaActiva,
                motivoDesactivacion: datosEmpresaHija.MotivoDesactivacion,
              })
            }
            setValue('empresaNit', empresa.Nombre)
            clearErrors('empresaNit')
            setValue('tipoAtencion', 2)
            clearErrors('tipoAtencion')
            setEmpresasFiltradas([])
            contextoRegistro.setExisteAtencion(true)
            contextoRegistro?.setEmpresasHijas(
              await ObtenerEmpresasHijasPorIdPadre(empresa.Id, true)
            )
            contextoRegistro.setTarifarioRegistro(
              (await ObtenerTarifarioPorEmpresaId(empresa.Id)).data
            )
            contextoRegistro.setActualizar(!contextoRegistro.actualizar)
            contextoAplicacion.setModal({
              abierto: true,
              titulo: (
                <>
                  <span className="font-bold">
                    EL PACIENTE TIENE UNA ORDEN DE SERVICIO
                  </span>
                  <br />
                  <span className="block">ASIGNADA POR LA EMPRESA:</span>
                  <br />
                </>
              ),
              subtitulo: (
                <span className="font-bold">
                  {empresa.Nombre.toUpperCase()}
                </span>
              ),
              contenido: (
                <>
                  <div className="w-full flex justify-center items-center flex-wrap text-cendiatra -mt-5">
                    <span className="w-full text-cendiatra text-lg">
                      <b>NÚMERO DE ORDEN: {res.data.id}</b>
                    </span>
                    {descontarSaldoPortalCliente ? (
                      <>
                        <span className="w-full text-cendiatra text-lg mt-3">
                          Por favor continúe con el registro.
                        </span>
                        <span className="w-full text-cendiatra text-lg">
                          El saldo disponible es:
                        </span>
                        <span className="mt-3 text-2xl font-bold ">
                          {aplicarFormatoMoneda(saldoDisponibleRef.current)}
                        </span>
                      </>
                    ) : (
                      <div className="w-full flex justify-center items-center flex-wrap text-cendiatra">
                        <span
                          className={`w-full text-cendiatra text-lg ${
                            moment(res.data.fechaAtencion).format('LTS') ==
                            '12:00:00 AM'
                              ? 'hidden'
                              : ''
                          }`}
                        >
                          <b>Hora solicitada para atención:</b>{' '}
                          {moment(res.data.fechaAtencion).format('LT')}
                        </span>
                      </div>
                    )}
                  </div>
                </>
              ),
              botones: [
                {
                  nombre: 'Continuar',
                  click: manejaCerrarModal,
                },
              ],
            })
          } else if (res.status == 204 || modoEdicion) {
            setOrdenPortal(false)
            contextoRegistro.setDescontarSaldoPortal(false)
            ObtenerInformacionPaciente(contextoRegistro.usuarioId, false)
              .then(async (res) => {
                if (res.data.direccion) {
                  contextoRegistro.setInformacionDireccion({
                    ...contextoRegistro.informacionDireccion,
                    celular: res.data.direccion.celular ?? '',
                    correoElectronico: res.data.direccion.correo ?? '',
                    departamento: (
                      await ObtenerDepartamentoPorId(
                        res.data.direccion.departamentoId
                      )
                    ).Nombre,
                    direccion: res.data.direccion.direccion ?? '',
                    estrato: res.data.direccion.estratoId ?? '',
                    indicativoCelular: res.data.direccion.indicativo ?? '',
                    localidad: res.data.localidadId
                      ? (
                          await ObtenerLocalidadPorId(
                            res.data.direccionAtencion.localidadId
                          )
                        ).Nombre
                      : null,
                    municipio: (
                      await ObtenerCiudadPorId(res.data.direccion.municipioId)
                    )[0].Nombre,
                    paisResidencia: 'COLOMBIA',
                    telefono: res.data.direccion.telefono ?? '',
                    zona: res.data.direccion.zonaId ?? '',
                  })
                }
                contextoRegistro.setInformacionGeneral({
                  ...contextoRegistro.informacionGeneral,
                  acompanante: res.data.acompanante,
                  arl: res.data.arlId
                    ? (await ObtenerArlPorId(res.data.arlId)).Nombre
                    : undefined,
                  composicionFamiliar: res.data.composicionFamiliarId,
                  discapacidad: res.data.discapacidad,
                  eps: res.data.epsId
                    ? (await ObtenerEpsPorId(res.data.epsId)).Nombre
                    : undefined,
                  fondoPension: res.data.fondoPensionesId
                    ? (
                        await ObtenerFondoDePensionPorId(
                          res.data.fondoPensionesId
                        )
                      ).Nombre
                    : undefined,
                  telAcompanante: res.data.telefonoAcompanante ?? '',
                  tipoPoblacion: res?.data?.tipoPoblacionId,
                })

                contextoRegistro.setActualizar(!contextoRegistro.actualizar)
              })
              .catch((error) => {
                console.log(error)
              })
          } else {
            contextoRegistro.setDescontarSaldoPortal(false)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [contextoRegistro.usuarioId])

  useEffect(() => {
    const tipoAtencion = contextoRegistro.informacionPersonal.tipoAtencion
    const atencionEmpresa = TIPO_ATENCION_REGISTRO.EMPRESA.toString()
    const facturacion = contextoRegistro.empresaSeleccionada.tipoFacturacion
    const Contado = TIPOS_FACTURACION.CONTADO

    if (
      !ordenportal &&
      tipoAtencion === atencionEmpresa &&
      facturacion === Contado
    ) {
      modalSaldoEmpresaContado()
      contextoRegistro.setDescontarSaldoPortal(true)
    }
  }, [contextoRegistro.empresaSeleccionada.nit, saldoDisponibleRef.current])

  const modalSaldoEmpresaContado = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <>
          <span className="text-xl font-light">
            EL SALDO EN LA CUENTA DEL CLIENTE ES:
          </span>
          <br />
        </>
      ),

      contenido: (
        <>
          <div className="w-full flex justify-center items-center flex-wrap text-cendiatra -mt-5">
            <>
              <span className="mt-3 text-2xl font-bold ">
                {aplicarFormatoMoneda(saldoDisponibleRef.current)}
              </span>
              <span className="w-full text-cendiatra text-xl font-bold my-10">
                CONTINUE CON EL REGISTRO
              </span>
            </>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Continuar',
          click: manejaCerrarModal,
        },
      ],
    })
  }

  //MODAL PARA MOSTRAR SALDO DISPONIBLE EN CUENTA PARTICULAR
  /*
  useEffect(() => {
    const tipoAtencion = contextoRegistro.informacionPersonal.tipoAtencion
    const atencionParticular = TIPO_ATENCION_REGISTRO.PARTICULAR.toString()

    if (tipoAtencion === atencionParticular && saldoParticular > 0) {
      modalParticular()
      contextoRegistro.setDescontarSaldoPortal(true)
    }
  }, [contextoRegistro.empresaSeleccionada.nit, saldoParticular])

  const modalParticular = () => {
    contextoAplicacion.setModal({
      abierto: true,
      contenido: (
        <>
          <div className="w-full flex justify-center items-center flex-wrap text-cendiatra -mt-5">
            <>
              <span className="w-full text-cendiatra text-lg mt-3">
                EL PACIENTE CON NÚMERO DE DOCUMENTO
              </span>
              <br />
              <span>
                {tipoDocumentoTransformado.current}{' '}
                {contextoRegistro.numeroDocumento}
              </span>
              <br />
              <span className="w-full text-cendiatra text-lg mt-10">
                tiene un saldo disponible:
              </span>
              <br />{' '}
              <span className="font-bold">
                {aplicarFormatoMoneda(saldoParticular)}
              </span>
            </>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Continuar',
          click: manejaCerrarModal,
        },
      ],
    })
  }*/

  useEffect(() => {
    const eliminarIndefinidos = () => {
      const paquetes = contextoRegistro.todosLosExamenes.filter((examen) => {
        if (examen.tipo === CLASIFICACION_SERVICIO.PAQUETE)
          return (
            respuestaPaquetes.find(
              (paquete) => paquete.examenId == examen.id
            ) != undefined
          )
      })

      const servicios = contextoRegistro.todosLosExamenes.filter((examen) => {
        if (examen.tipo === CLASIFICACION_SERVICIO.SERVICIO) {
          return (
            respuestaServicios.find(
              (servicio) => servicio.examenId == examen.id
            ) != undefined
          )
        }
      })

      const examenes = paquetes.concat(servicios)

      if (examenes.length) {
        contextoRegistro.setExamenesSeleccionados(examenes)
      }
    }
    if (
      contextoRegistro.todosLosExamenes.length > 0 &&
      !contextoRegistro.cancelarValidacionServiciosYPaquetes
    ) {
      eliminarIndefinidos()
    }
  }, [contextoRegistro.todosLosExamenes.length])

  const manejarNumeroDocumento = async (e) => {
    if (e.target.value == '') {
      contextoRegistro.setUsuarioId('')
      contextoRegistro.setExisteAtencion(false)
    }
    contextoRegistro.setNumeroDocumento(e.target.value.replace(/^(0+)/g, ''))
  }

  const manejarTipoDeDocumento = async (e) => {
    if (e.target.value == '') {
      contextoRegistro.setUsuarioId('')
      contextoRegistro.setExisteAtencion(false)
    }
    contextoRegistro.setTipoDocumentoId(e.target.value)
    setValue('tipoDocumento', e.target.value)
    clearErrors('tipoDocumento')

    patternReglas(e.target.value)
  }

  const patternReglas = (valor) => {
    if (valor === '12') {
      setPattern(/^[0-9-]+$/)
    }

    if (valor === '1' || (valor > 4 && valor < 12)) {
      setPattern(/^[0-9]+$/)
    }

    if (valor > 1 && valor < 5) {
      setPattern(/^[a-zA-Z0-9]*$/)
    }
  }

  useEffect(() => {
    const obtenerInformacionCuenta = async () => {
      try {
        if (contextoRegistro.empresaSeleccionada.id) {
          const respuesta = await ObtenerInformacionCuentaPorEmpresaId(
            contextoRegistro.empresaSeleccionada.id
          )
          saldoDisponibleRef.current = respuesta?.data?.saldo || 0
        }
      } catch (error) {
        console.error(error)
      }
    }

    obtenerInformacionCuenta()
  }, [
    contextoRegistro.empresaSeleccionada.id,
    contextoRegistro.tipoDocumentoId,
    contextoRegistro.numeroDocumento,
  ])

  //Consulta saldo particular
  /*
  useEffect(() => {
    const obtenerInformacionCuentaPorUsuarioId = async () => {
      if (!contextoRegistro.usuarioId) return
      try {
        const respuesta = await ObtenerInformacionCuentaPorUsuarioId(
          contextoRegistro.usuarioId
        )
        setSaldoParticular(respuesta?.data?.saldo || 0)
      } catch (error) {
        console.error(error)
      }
    }

    obtenerInformacionCuentaPorUsuarioId()
  }, [contextoRegistro.usuarioId])
  */

  return (
    <div
      className={`w-full flex flex-wrap justify-center items-center ${
        deshabilitarEdicion ? 'pointer-events-none' : ''
      }`}
    >
      <input
        type="text"
        tabIndex="2"
        onBlur={(e) => manejarLectorDeCodigo(e, 2)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="3"
        onBlur={(e) => manejarLectorDeCodigo(e, 3)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="4"
        onBlur={(e) => manejarLectorDeCodigo(e, 4)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="5"
        onBlur={(e) => manejarLectorDeCodigo(e, 5)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="6"
        onBlur={(e) => manejarLectorDeCodigo(e, 6)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="7"
        onBlur={(e) => manejarLectorDeCodigo(e, 7)}
        className="h-0 w-0"
      />
      <input
        type="text"
        tabIndex="8"
        onChange={(e) => manejarLectorDeCodigo(e, 8)}
        className="h-0 w-0"
      />

      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="numeroDocumento"
          control={control}
          rules={{
            required: true,
            pattern: regexSoloLetrasYNumeros,
            onChange: (e) => manejarNumeroDocumento(e),
            minLength: infoNumeroCaracteres.minimo,
            maxLength: infoNumeroCaracteres.maximo,
          }}
          render={({field: {onChange, value}}) => (
            <div className="w-3/12 flex justify-start flex-wrap">
              <img
                className="w-7 h-7 absolute -ml-10 mt-8"
                src={CodigoQr}
                alt="codigoQR"
              />
              <Input
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'numeroDocumento'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={infoNumeroCaracteres.tipoCampo || 'text'}
                tabIndex={'1'}
                placeholder={'DESCRIPCIÓN'}
                titulo={'No. Documento*'}
                valor={value}
                id={'primerInput'}
                onChange={onChange}
              />
              <ErroresInput
                erroresFormulario={errors}
                nombre={'numeroDocumento'}
                tipoError={'pattern'}
                mensaje={'Se permiten letras y números'}
              />
            </div>
          )}
        />

        <Controller
          name="tipoDocumento"
          control={control}
          rules={{
            required: true,
          }}
          render={() => (
            <ListaDesplegable
              funcion={manejarTipoDeDocumento}
              estilosContenedor={'w-3/12'}
              estilosLista={
                Object.keys(errors).find(
                  (element) => element === 'tipoDocumento'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de documento*'}
              opciones={tipoDocumento}
              valor={contextoRegistro.tipoDocumentoId}
            />
          )}
        />
        <Controller
          name="tipoAtencion"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {value}}) => (
            <ListaDesplegable
              funcion={manejarTipoAtencion}
              estilosContenedor={'w-3/12'}
              estilosLista={
                Object.keys(errors).find(
                  (element) => element === 'tipoAtencion'
                )
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : `appearance-none rounded relative block w-full  p-1.5 border border-cendiatra  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ${
                      contextoRegistro.existeAtencion
                        ? 'pointer-events-none bg-cendiatra-gris-placeholder text-white'
                        : 'text-cendiatra-gris-3 '
                    }`
              }
              titulo={'Tipo de atención*'}
              opciones={contextoAplicacion.tipoAtencion}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-start items-center">
        <AlertaLongitudDocumento
          tipoDocumento={contextoRegistro.tipoDocumentoId}
          informacionCaracteres={infoNumeroCaracteres}
          setInformacionCaracteres={setInfoNumeroCaracteres}
          numeroDocumento={contextoRegistro.numeroDocumento}
          errors={errors}
        />
      </div>
      <div className="w-full flex justify-evenly items-center my-2">
        <div className="w-6/12 flex flex-wrap justify-evenly items-center">
          <Controller
            name="empresaNit"
            control={control}
            rules={{
              required: true,
              pattern: regexNombreEmpresaHC,
              onChange: (e) => manejarEmpresaCambiada(e),
              validate: (e) => validarExisteEmpresa(e),
            }}
            render={({field: {onChange, value}}) => (
              <>
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-11/12 mb-5'}
                  titulo={'Empresa (NIT)*'}
                  estilosInput={
                    Object.keys(errors).find(
                      (element) => element === 'empresaNit'
                    )
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : `appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ${
                          contextoRegistro.empresaSeleccionada.tipoAtencion ===
                          TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
                            ? 'pointer-events-none bg-cendiatra-gris-placeholder  text-white'
                            : 'text-cendiatra-gris-3 '
                        }`
                  }
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={value}
                  desactivarOtroFiltro={
                    contextoRegistro.empresaSeleccionada.tipoAtencion ===
                    TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
                  }
                  informacionFiltrada={empresasFiltradas}
                  handleChange={onChange}
                  handleOptionChange={manejarEmpresaSeleccionada}
                />
                <span className="text-red-500 w-full">
                  {errors.empresaNit?.type === 'pattern' &&
                    TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                </span>
              </>
            )}
          />
          {contextoRegistro.sedeNoExiste ? (
            <div className="w-11/12 flex justify-center items-center bg-cendiatra-gris-5 py-2 mb-1 rounded-lg">
              <div className="w-1/12 flex justify-center items-center">
                <img src={IconoAlerta} className={'w-5 h-5'} alt="alerta" />
              </div>
              <div className="w-11/12">
                <span className="text-cendiatra-semaforo-rojo text-13px">
                  {`La empresa seleccionada no tiene tarifario asignado para la sede ${contextoAplicacion.sede.nombreSede}`}
                </span>
              </div>
            </div>
          ) : null}
          <Controller
            name="empresaHijaNit"
            control={control}
            rules={{
              required: false,
            }}
            render={() => (
              <>
                <CampoFiltrarEspañol
                  estilosPersonalizados={'w-11/12'}
                  titulo={'Empresa hija (NIT)'}
                  estilosInput={
                    empresaHijaNoValida
                      ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                      : `appearance-none rounded relative block w-full  p-1.5 border border-cendiatra rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ${
                          contextoRegistro.empresaSeleccionada.tipoAtencion ===
                          TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
                            ? 'pointer-events-none bg-cendiatra-gris-placeholder text-white placeholder-white'
                            : 'text-cendiatra-gris-3 '
                        }`
                  }
                  tipo={'text'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={
                    contextoRegistro.empresaHijaSeleccionada.nombre
                  }
                  desactivarOtroFiltro={
                    contextoRegistro.empresaSeleccionada.tipoAtencion ===
                    TEXTO_VISUAL.TIPO_ATENCION.PARTICULAR
                  }
                  informacionFiltrada={empresasHijasFiltradas}
                  handleChange={manejarEmpresaHija}
                  handleOptionChange={manejarEmpresaHijaSeleccionada}
                />
                <span className="text-red-500 w-11/12">
                  {empresaHijaNoValida &&
                    'No se escontro una esmpresa hija con ese nombre o nit'}
                </span>
              </>
            )}
          />
        </div>
        <div className="w-6/12 flex justify-evenly items-center ">
          <Controller
            name="observacionesEmpresa"
            control={control}
            rules={{
              required: false,
            }}
            render={({field: {onChange}}) => (
              <AreaDeTexto
                estilosContenedor={'w-11/12'}
                estilosAreaDeTexto={
                  'appearance-none h-32 bg-cendiatra-gris-placeholder rounded relative block w-full  p-1.5 border border-cendiatra text-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
                }
                titulo={'Observaciones de la empresa o empresa hija'}
                valor={
                  contextoRegistro.empresaHijaSeleccionada.observaciones != ''
                    ? contextoRegistro.empresaHijaSeleccionada.observaciones
                    : contextoRegistro.empresaSeleccionada.observaciones
                }
                deshabilitado={true}
                cambioValor={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="apellidos"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'apellidos')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Apellidos*'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.apellidos?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />

        <Controller
          name="nombre"
          control={control}
          rules={{
            required: true,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find((element) => element === 'nombre')
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nombre*'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nombre?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
        <Controller
          name="grupoSanguineo"
          control={control}
          rules={{
            required: false,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
              titulo={'Grupo sanguíneo'}
              opciones={contextoAplicacion.gruposSanguineos}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-evenly items-center my-2">
        <Controller
          name="fechaNacimiento"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosInput={clsx(
                'appearance-none rounded relative block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                {
                  'border border-cendiatra-rojo-1 text-cendiatra-gris-placeholder focus:border-cendiatra-rojo-1':
                    Object.keys(errors).includes('fechaNacimiento') ||
                    fechaEsValida,
                  'border border-cendiatra text-cendiatra-gris-3 focus:border-cendiatra':
                    !Object.keys(errors).includes('fechaNacimiento') &&
                    !fechaEsValida,
                }
              )}
              tipo={'date'}
              placeholder={'Descripción'}
              fechaMinima={obtenerFechaMinima(anioFechMinima)}
              fechaMaxima={fechaDeHoy}
              titulo={'Fecha de nacimiento*'}
              valor={value}
            />
          )}
        />
        <Controller
          name="sexo"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'sexo')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Sexo*'}
              opciones={contextoAplicacion.sexos}
              valor={value}
            />
          )}
        />
        <Controller
          name="estadoCivil"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'estadoCivil')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Estado civil*'}
              opciones={contextoAplicacion.estadosCiviles}
              valor={value}
            />
          )}
        />
      </div>
      <div className="w-full">
        <div className="w-31% ml-7">
          <AlertaDinamica
            mostrarAlerta={fechaEsValida}
            mensaje={ALERTAS.EDAD_MAXIMA}
          />
        </div>
      </div>
      <div className="w-full flex justify-evenly items-center mt-2 mb-50px">
        <Controller
          name="lugarNacimiento"
          control={control}
          rules={{
            required: false,
            pattern: regexTextoCaracteresBasicos,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === 'lugarNacimiento'
                  )
                    ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                    : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
                }
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Lugar de nacimiento'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.lugarNacimiento?.type === 'pattern' &&
                  'Se permiten estos caracteres + ( ) [ ] , / - . '}
              </span>
            </div>
          )}
        />
        <Controller
          name="escolaridad"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-31%'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'escolaridad')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Escolaridad*'}
              opciones={contextoAplicacion.escolaridades}
              valor={value}
            />
          )}
        />
        <Controller
          name="nacionalidad"
          control={control}
          rules={{
            required: false,
            pattern: regexBasicoV1,
          }}
          render={({field: {onChange, value}}) => (
            <div className="flex flex-wrap w-31%">
              <Input
                onChange={onChange}
                estilosContenedor={'w-full'}
                estilosInput="appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm"
                tipo={'text'}
                placeholder={'Descripción'}
                titulo={'Nacionalidad'}
                valor={value}
              />
              <span className="text-cendiatra-semaforo-rojo w-full text-13px">
                {errors.nacionalidad?.type === 'pattern' &&
                  TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
              </span>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default InformacionPersonal
