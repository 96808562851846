const PESTANA_NOMBRAR_PORTAFOLIO = {
  TITULO: 'Nombrar portafolio',
  FORMULARIO: {
    CODIGO_PORTAFOLIO: {
      LABEL: 'Código del Portafolio',
      PLACEHOLDER: '(Autoincrementar)',
    },
    NOMBRE_PORTAFOLIO: {
      LABEL: 'Nombre Portafolio*',
      PLACEHOLDER: 'Descripción (Autocompletar Y Filtrar)',
      ERROR: {
        REPETIDO:
          'El nombre del portafolio especificado ya se encuentra registrado',
      },
    },
    FECHA_INICIAL: {
      LABEL: 'Fecha Inicial*',
    },
    FECHA_FINAL: {
      LABEL: 'Fecha Final',
    },
  },
}

const PESTANA_SELECCIONAR_SEDES = {TITULO: 'Seleccionar sedes'}
const PESTANA_SELECCIONAR_ALIADOS = {TITULO: 'Seleccionar aliados'}
const PESTANA_ASIGNAR_PRECIOS = {TITULO: 'Asignar precios y alias'}

export const TEXTO_VISUAL = {
  TEXTO_GENERAL: {
    TOTAL: 'Total',
    ELEGIR_MANUALMENTE: 'ELEGIR MANUALMENTE',
  },
  BARRA_NAVEGACION: {
    PORTAFOLIO: {
      TITULO: 'Portafolio',
      CREAR: 'Crear',
      EDITAR: 'Ver / Modificar',
    },
    MENU_ADMINISTRATOR_GATO: {
      GESTION_DE_ROL: 'Gestión de Rol',
      GESTION_FIRMA: 'Gestión Firma Historia Clínica Usuario Medico',
      GENERADOR_DE_REPORTES: 'Generador De Reportes',
      GESTION_DE_REPORTES: 'Gestión De Reportes',
    },
    MENU_RECEPCIONISTA: {
      ASIGNAR_TURNO: 'Asignar turno',
      LLAMAR_PACIENTES: 'Llamar pacientes para atenderlos',
      REGISTRO_DE_PACIENTES: 'Registro de paciente',
      AGENDA_REGISTRO: 'Agenda registro',
      VER_MODIFICAR_PACIENTES: 'Ver/Modificar pacientes',
      MODIFICAR_ATENCION_EJECUTADA: 'Modificar atención ejecutada',
      GESTION_DE_FACTURACION: 'Gestión de facturación',
      CIERRE_DE_CAJA: 'Cierre de caja diario',
      RECARGAR_EN_SEDE: 'Recarga saldo de cliente en sede',
      CONSULTA_TARIFARIO_PARTICULAR: 'Consulta tarifario particular',
      GENERADOR_DE_INFORMES: 'Generador De Informes',
      VER_SALDO_DEL_CLIENTE: 'Ver saldo del Cliente',
    },
    //Menu administrador contable
    MENU_ADMINISTRADOR_CONTABLE: {
      GESTION_RESOLUCIONES: 'Gestión De Resoluciones Para Factura Electrónica',
      GESTION_FACTURA: 'Gestión De Documentación Electrónica',
      CREAR_NOTA_CREDITO: 'Creación De Nota Crédito',
      GESTION_APROBACION: 'Gestión Aprobación Nota Crédito',
      GESTION_COMPROBANTE: 'Gestión Comprobante De Atención',
    },
    AUX_CONTABLE: {
      CONTABILIDAD: 'Contabilidad',
    },
    MENU_BACTERIOLOGO: {
      REGISTRAR_RESULTADOS:
        'Registrar Resultados de Exámenes Laboratorio Clínico',
      VER_MODIFICAR_EXAMENES: 'Ver / Modificar Exámenes Atendidos',
    },
    MENU_FONOAUDIOLOGO: {
      VER_MODIFICAR_ATENCION: 'Ver / Modificar Exámenes Atendidos',
      REPORTE_ATENCION: 'Registrar Resultados de Exámenes Laboratorio Clínico',
    },
    MENU_JEFE_DE_SEDE: {
      HABILITAR_MODIFICACION_DE_EXAMENES:
        'Habilitar modificación de exámenes atendidos',
      REPORTE_EJECUCION_DIARIA_POR_SEDE: 'Reporte ejecución diaria sede',
      RESUMEN_POR_ENTIDAD: 'Resumen por entidad',
      RESUMEN: '¡Este resumen muestra la información de los últimos 30 días!',
      UNIFICAR_DATOS_DEL_PACIENTE: 'Unificar datos del paciente',
    },
    MENU_GERENTE_GENERAL: {
      REPORTE_DE_VENTAS: 'Reporte de ventas',
      REGENERAR_CIERRE_CAJA: 'Ver / Regenerar cierre de caja',
    },
    MENU_EJECUTIVO_COMERCIAL: {
      GESTION_USUARIOS_PORTAL_CLIENTE: 'Gestión de usuarios del portal cliente',
      VER_ATENCION_EMPRESAS: 'Ver atenciones',
    },
    MENU_GERENTE_FINANCIERO: {
      SERVICIOS: 'Servicios',
      VER_SERVICIOS: 'Ver Servicios',
      PAQUETES: 'Paquetes',
      VER_PAQUETES: 'Ver Paquetes',
    },
    MOTOR_DE_PLANTILLAS: {
      TITULO: 'Motor De Plantillas',
      CREAR: 'Crear',
      VER_MODIFICAR: 'Ver / Modificar',
      ASIGNAR_PLANTILLA_SERVICIO: 'Asignar Plantilla a Servicio',
      CONTROL_DE_VERSIONES: 'Control Versión de Plantillas',
      CREAR_EDITAR_FUENTE_DATOS: 'Crear / Editar fuente de datos',
    },
    GESTION_FACTURACION: {
      TITULO: 'Gestión de Facturación',
      FACTURAS_PENDIENTES_CORRECCION_SEDE:
        'Facturas pendientes de corrección en sede',
      CONSULTA_ESTADO_FACTURACION: 'Consulta estado de facturación',
    },
    MEDICO_OCUPACIONAL: {
      AGENDA_MEDICO: 'Agenda Medico',
      VER_MODIFICAR_EXAMENES_ATENDIDOS: {
        TITULO: 'Ver / modificar exámenes atendidos',
      },
      REPORTE_ATENCION_DIARIA: 'Reporte de Atención Diaria Pacientes',
    },
    RECEPCIONISTA: {
      MODIFICAR_ATENCION_EJECUTADA: {
        TITULO: 'MODIFICAR ATENCIÓN EJECUTADA',
        TUTULO_MODIFICAR_EMPRESA_HIJA_CARGO: 'MODIFICAR ATENCIÓN',
        TITULO_CANCELAR: 'CANCELAR ATENCIÓN',
        TEXTO_BARRA:
          'RECUERDE QUE SOLO PUEDE MODIFICAR ATENCIONES QUE SE HAYAN REALIZADO MÁXIMO 30 DÍAS ANTES',
        MODAL_ATENDIDO: {
          TITULO:
            'EL PACIENTE ESTÁ SIENDO ATENDIDO POR UN MÉDICO POR FAVOR ESPERE A QUE TERMINE LA ATENCIÓN',
        },
        MODAL_MOTIVO: {
          TITULO: 'MOTIVO POR EL CUAL DESEA HACER LA MODIFICACIÓN',
        },
        MODAL_MODIFICAR: {
          TITULO: 'QUE DESEA MODIFICAR',
        },
      },
      GENERADOR_DE_INFORMES: {
        TITULO: 'GENERADOR DE INFORMES',
      },
    },
    SALDO: {
      VER_SALDO_PORTAL_CLIENTE: 'Ver saldo del Cliente',
    },
    MENU_PROFESIONAL_SST: {
      INFORME_CONDICIONES_DE_SALUD: 'Informe Condiciones de Salud',
    },
  },

  BOTONES: {
    CARGAR: 'Cargar',
    CREAR_NUEVO: 'Crear Nuevo',
    FINALIZAR: 'Finalizar',
    GUARDAR: 'Guardar',
    REGRESAR: 'Regresar',
    VOLVER: 'Volver',
    NO: 'No',
    SI: 'Si',
    CERRAR: 'Cerrar',
    IR_INICIO: 'Ir al inicio',
    REGRESAR_A_EXAMEN: 'Regresar a examen',
  },
  PORTAFOLIO: {
    CREAR: {
      TITULO: 'Crear portafolio',
      PESTANA: {
        NOMBRAR_PORTAFOLIO: PESTANA_NOMBRAR_PORTAFOLIO,
        SELECCIONAR_SEDES: PESTANA_SELECCIONAR_SEDES,
        SELECCIONAR_ALIADOS: PESTANA_SELECCIONAR_ALIADOS,
        ASIGNAR_PRECIOS: PESTANA_ASIGNAR_PRECIOS,
      },
    },
    EDITAR: {
      TITULO: 'Ver modificar portafolio',
      PESTANA: {
        SELECCIONAR_PORTAFOLIO: {
          TITULO: 'Seleccionar Portafolio',
          COLUMNA: {
            CODIGO_PORTAFOLIO: {
              TITULO: 'Código Del Portafolio',
            },
            NOMBRE_PORTAFOLIO: {
              TITULO: 'Nombre Portafolio',
            },
            FECHA_MODIFICACION: {
              TITULO: 'Fecha de última modificación',
            },
            EMPRESA_PORTAFOLIO_ASIGNADO: {
              TITULO: 'Empresa con portafolio asignado',
            },
            EDITAR: {
              TITULO: 'Editar',
            },
          },
        },
        NOMBRAR_PORTAFOLIO: PESTANA_NOMBRAR_PORTAFOLIO,
        SELECCIONAR_SEDES: PESTANA_SELECCIONAR_SEDES,
        SELECCIONAR_ALIADOS: PESTANA_SELECCIONAR_ALIADOS,
        ASIGNAR_PRECIOS: PESTANA_ASIGNAR_PRECIOS,
      },
    },
    MODAL: {
      PORTAFOLIO_GUARDADO: (creando = true) => ({
        TITULO: creando ? 'Portafolio creado' : 'Portafolio modificado',
        ID: 'id',
        CODIGO_PORTAFOLIO: 'Codigo de portafolio',
        NOMBRE_PORTAFOLIO: 'Nombre portafolio',
      }),
    },
  },
  TARIFARIO: {
    CREAR_TARIFARIO: 'CREAR TARIFARIO',
    VER_MODIFICAR_TARIFARIOS: 'VER Y MODIFICAR TARIFARIO',
    VER_TARIFARIOS: 'VER TARIFARIO',
    MODAL: {
      TARIFARIO_GUARDADO: (creando = true) => ({
        TITULO: creando ? 'Tarifario creado' : 'Tarifario editado',
      }),
    },
    MODAL_CARGAR_PORTAFOLIO: {
      TITULO: 'TARIFARIO MODIFICADO',
      TEXTO_PRIMERA_LINEA: ' LOS SERVICIOS DEL TARIFARIO HAN SIDO REEMPLAZADOS',
      TEXTO_SEGUNDA_LINEA: 'POR LOS DEL PORTAFOLIO',
    },
  },
  BACTERIOLOGIA: {
    RESULTADOS_EXAMEN: 'REGISTRAR RESULTADOS EXÁMENES DE LABORATORIO CLÍNICO',
    TOMA_DE_MUESTRAS: 'TOMA DE MUESTRAS LABORATORIO',
  },
  GESTION_FACTURACION: {
    FACTURAS_PENDIENTES_CORRECCION_SEDE: {
      TITULO: 'Facturas pendientes de corrección en sede',
      INDICADOR: {
        FACTURAS_NO_GENERADAS_REQUIEREN_CORRECCION: {
          TITULO: 'Facturas no generadas y requieren corrección',
          LABEL: {
            HOY: 'Hoy',
            AYER: 'Ayer',
            ANTERIORES: 'Anteriores',
          },
        },
      },
      COLUMNA: {
        NUMERO_FACTURA: {TITULO: 'Número de Factura', PLACEHOLDER: 'Buscar'},
        SEDE: {TITULO: 'Sede'},
        FECHA_EMISION_FACTURA: {
          TITULO: 'Fecha de emisión factura',
          PLACEHOLDER: 'Todos',
        },
        IDENTIFICACION_NOMBRE_CLIENTE: {
          TITULO: 'Identificación y nombre del cliente',
          PLACEHOLDER: 'Buscar',
        },
        VALOR_TOTAL: {TITULO: 'Valor total'},
        ESTADO_FACTURA: {
          TITULO: 'Estado de factura',
          VALOR: 'Factura no generada requiere corrección',
        },
        DESCRIPCION_ERROR: {
          TITULO: 'Descripción de error transmisión y/o rechazada',
          ERRORES: (errores) => `Ver ${errores} errores`,
        },
      },
    },
    CORRECCION_ERRORES_TRANSMISION_FIRMA_ELECTRONICA: {
      TITULO: 'Ver y corregir errores de transmisión factura electrónica',
      INFORMACION_FACTURA: {
        TITULO: 'Información de la factura',
        FECHA_FACTURA: 'Fecha de factura',
        REALIZADO_POR: 'Realizado por',
        VALOR_TOTAL_FACTURA: 'Valor total factura',
        NUMERO_FACTURA: 'Número de factura',
        SEDE: 'Sede',
      },
      FORMULARIO: {
        TIPO_DOCUMENTO: 'Tipo de documento',
        IDENTIFICACION: 'Nit o número de identificación',
        CODIGO_VERIFICACION: 'Código de verificación',
        CLIENTE: 'Cliente',
        DEPARTAMENTO: 'Departamento',
        CIUDAD: 'Ciudad',
        DIRECCION: 'Dirección',
        CODIGO_POSTAL: 'Código postal',
        ACTIVIDAD_ECONOMICA: 'Actividad económica',
        MATRICULA: 'Matrícula',
        CASILLA_53: 'Casilla 53',
        CORREO_ELECTRONICO: 'Correo electrónico',
        BOTON_GUARDAR: 'Guardar y transmitir',
      },
    },
    MODAL: {
      FACTURA_ACTUALIZADA: {
        TITULO: (numeroFactura) =>
          `Se han actualizado los datos que generaron inconsistencia en la validación del facturador electrónico para la factura ${numeroFactura}`,
        MENSAJE:
          'La factura será transmitida automáticamente para ser validada nuevamente',
      },
    },
  },
  TITULOS_RECEPCIONISTA: {
    ASIGNAR_TURNO: 'ASIGNAR TURNO',
    LLAMADO_DE_PACIENTE: 'LLAMADO DE PACIENTE',
    REGISTRO_DE_PACIENTE: 'REGISTRO DE PACIENTE',
    AGENDA_REGISTRO: 'AGENDA REGISTRO',
    MODIFICAR_DATOS_PACIENTE: 'MODIFICAR DATOS PACIENTE',
    VER_MODIFICAR_PACIENTES: 'VER / MODIFICAR PACIENTES',
    CONSULTA_ESTADO_FACTURACION: 'CONSULTA ESTADO DE FACTURACIÓN',
    CIERRE_DE_CAJA: 'CIERRE DE CAJA',
  },
  TITILO_ADMINISTRADOR_GATO: {
    GESTION_DE_ROL: 'GESTION DE ROL',
    GESTION_FIRMA_HISTORIA_CLINICA_USUARIO_MEDICO:
      'GESTIÓN FIRMA HISTORIA CLÍNICA USUARIO MÉDICO',
    CREAR_PLANTILLA: 'CREAR PLANTILLA',
    CREAR_NUEVA_VERSION_PLANTILLA: 'NUEVA VERSIÓN DE PLANTILLA',
    EDITAR_BORRADOR_PLANTILLA: 'EDITAR BORRADOR VERSIÓN DE PLANTILLA',
    GESTION_DE_ASIGNACION_DE_PLANTILLA_SERVICIO:
      ' GESTIÓN DE ASIGNACIÓN DE PLANTILLA A SERVICIO',
    ASIGNAR_SERVICIOS_A_ROL: 'ASIGNAR SERVICIOS A ROL',
  },
  TITULO_ADMINISTRADOR_ALIADO: {
    CARGA_RESULTADO_ALIADO: 'CARGA RESULTADO ALIADO',
    VER_MODIFICAR_RESULTADOS_ALIADO: 'VER / MODIFICAR RESULTADOS ALIADO',
    VER_RESULTADOS_ALIADO: 'VER RESULTADOS ALIADO',
    MODIFICAR_RESULTADO_ALIADO: 'MODIFICAR RESULTADOS ALIADO',
  },
  TITULO_ADMINISTRADOR_CONTABLE: {
    GESTIÓN_DE_DOCUMENTACION_ELECTRONICA:
      'GESTIÓN DE DOCUMENTACIÓN ELECTRÓNICA',
  },
  TITULO_ROL_MEDICO: {
    AGENDA_MEDICO: 'AGENDA MÉDICO',
  },
  TITULO_JEFE_DE_SEDE: {
    HABILITAR_MODIFICACION_DE_EXAMENES:
      'HABILITAR MODIFICACIÓN DE EXÁMENES ATENDIDOS',
    UNIFICACION_DATOS_PACIENTE: 'Unificación de datos del paciente',
  },
  RESOLUCION_FIRMA_ELECTRONICA: {
    TITULO: {
      VER_RESOLUCIONES: 'Gestión de resoluciones para factura electrónica',
      CARGAR_RESOLUCION: 'Cargar resolución factura electrónica',
      MODIFICAR_RESOLUCION: 'Modificar resolución factura electrónica',
    },
    BOTON: {
      CREAR_RESOLUCION: {TITULO: 'Cargar Resolución'},
    },
    COLUMNA: {
      CODIGO_RESOLUCION: {TITULO: 'Código De Resolución'},
      NUMERO_RESOLUCION: {
        TITULO: 'Número De Resolución',
        PLACEHOLDER: 'Numero(Autocompletar)',
      },
      FECHA_INICIAL: {TITULO: 'Fecha Inicial'},
      FECHA_FINAL: {TITULO: 'Fecha Final'},
      NUMERACION_INICIAL: {TITULO: 'Numeración Inicial'},
      NUMERACION_FINAL: {TITULO: 'Numeración Final'},
      NUMERACION_ACTUAL: {TITULO: 'Numeración Actual'},
      EDITAR: {TITULO: 'Editar'},
    },
    SEMAFORO: {
      RESOLUCION_ACTIVA: 'Resolución activa',
      RESOLUCION_PROXIMA_VENCER: 'Resolución próxima a vencer',
      RESOLUCION_VENCIDA: 'Resolución vencida',
    },
    FORMULARIO: {
      FECHA_CREACION: {LABEL: 'Fecha Creación'},
      NUMERO_RESOLUCION: {
        LABEL: 'Nùmero Resolución',
        PLACEHOLDER: 'Numérico',
      },
      PREFIJO: {
        LABEL: 'Prefijo',
        PLACEHOLDER: 'Descripción',
      },
      PREFIJO: {
        LABEL: 'Prefijo',
        PLACEHOLDER: 'Descripción',
      },
      ACTIVO: {LABEL: 'Activo'},
      SECCION_FECHA_AUTORIZACION: {
        TITULO: 'Fecha Autorización',
        FECHA_INICIAL: {LABEL: 'Fecha Inicial'},
        FECHA_FINAL: {LABEL: 'Fecha Final'},
      },
      SECCION_CONSECUTIVO_AUTORIZACION: {
        TITULO: 'Consecutivo Autorización',
        CONSECUTIVO_INICIAL: {
          LABEL: 'Consecutivo Inicial',
          PLACEHOLDER: 'Numérico',
        },
        CONSECUTIVO_FINAL: {
          LABEL: 'Consecutivo Final',
          PLACEHOLDER: 'Numérico',
          ERROR: {
            ES_MENOR_NUMERACION_INICIAL:
              'La numeración final no puede ser menor o igual a la numeración inicial',
          },
        },
      },
    },
    MODAL: {
      GUARDADO_EXITOSO: {
        TITULO: (esCreacion) =>
          `Resolución de la factura electrónica ha sido ${
            esCreacion ? 'cargada' : 'editada'
          }`,
        CODIGO_RESOLUCION: 'Código de resolución cargada',
        NUMERO_RESOLUCION: 'Número resolución',
        PREFIJO: 'Prefijo',
        ACTIVO: 'Activo',
        FECHA_INICIAL: 'Fecha inicial',
        FECHA_FINAL: 'Fecha final',
        NUMERACION_INICIAL: 'Numeración inicial',
        NUMERACION_FINAL: 'Numeración final',
      },
    },
  },
  CREAR_NOTA_CREDITO: {
    TITULO: 'CREAR NOTA CRÉDITO',
  },
  FORMULARIO: {
    CORREO_INVALIDO: 'El valor ingresado no es un correo válido',
    VALOR_INVALIDO_CAMPO_TEXTO:
      'Solo se permiten estos caracteres + ( ) [ ] , / - . ',
    VALOR_INVALIDO_SOLO_NUMEROS_POSITIVOS: 'Sólo se permiten números positivos',
    VALOR_REQUERIDO: 'Este campo es requerido, por favor llénelo',
    FECHA_FINAL_ES_MENOR_INICIAL:
      'La fecha final no puede ser menor o igual a la fecha inicial',
    VALOR_INVALIDO_CAMPO_NIT:
      "Solo se perten números y estos caracteres + ( ) [ ] , / - . ' ",
  },
  MODAL: {
    PRECIOS_EN_CERO: {
      TITULO: 'Los siguientes servicios y/o paquetes tendrán precio $0.00,00',
      DESEA_GUARDAR: '¿Desea guardarlos?',
      SEDES: 'Sedes',
      ALIADOS: 'Aliados ',
    },
  },
  CONTENEDOR_ERRORES: {TITULO: 'Errores'},
  ACCESO_DENEGADO: {
    TITULO: 'Acceso denegado',
    MENSAJE:
      'Estimado usuario, lamentamos informarle que no cuenta con autorización para ingresar a la ruta que esta intentando acceder. Si considera que debería ser capaz de hacerlo, por favor contactese con el administrador.',
  },
  EJECUTIVO_COMERCIAL: {
    CREAR_SERVICIO: 'CREAR SERVICIO',
    MODIFICAR_DATOS_SERVICIO: 'MODIFICAR DATOS SERVICIO',
    VER_SERVICIOS: 'VER SERVICIOS',
    CREAR_EMPRESA: 'CREAR EMPRESA',
    VER_Y_MODIFICAR_EMPRESA: 'VER Y MODIFICAR EMPRESA',
    VER_EMPRESA: 'VER EMPRESA',
    CREAR_EMPRESA_HIJA: 'CREAR EMPRESA HIJA',
    VER_Y_MODIFICAR_EMPRESA_HIJA: 'VER EMPRESA HIJA',
    VER_EMPRESA_HIJA: 'VER EMPRESA HIJA',
    CREAR_USUARIO_PORTAL_CLIENTE: 'Crear usuario del portal cliente',
    EDITAR_USUARIO_PORTAL_CLIENTE: 'Editar usuario del portal cliente',
    CREAR_PAQUETE: 'CREAR PAQUETE',
    EDITAR_PAQUETE: 'EDITAR PAQUETE',
    VER_PAQUETES: 'VER PAQUETES',
    DETALLE_DEL_PAQUETE: 'DETALLE DEL PAQUETE',
  },
  GERENTE_COMERCIAL: {
    GESTION_DE_PRECIOS_PARA_OTROS_SERVICIOS:
      'Gestión de precios para Otros Servicios',
  },
  ADMINISTRADOR_GATO: {
    GENERADOR_DE_REPORTES: {
      TITULO: 'CREAR REPORTES',
    },
  },
  TESORERO: {
    CARGA_ARCHIVOS: {
      TITULO: 'CARGA DE ARCHIVO DE BANCOS',
    },
  },
  GERENTE_GENERAL: {
    REGENERAR_CIERRE_CAJA: 'VER / REGENERAR CIERRE DE CAJA',
  },
  TIPO_ATENCION: {
    PARTICULAR: 'PARTICULAR',
  },
  REGEX_MENSAJES: {
    CARACTERES_PERMITIDOS_V1:
      'Solo se permiten los siguientes caracteres: + () [] / - . _ ,&',
    CARACTERES_PERMITIDOS_V2:
      'Solo se permiten los siguientes caracteres: + () [] / - . _ , & *',
  },
  CONFIRMACION_PAGOS: {
    TITULO_PAGINA: 'LISTADO DE CONFIRMACIÓN',
    TITULO_MENU: 'Confirmación de pagos',
  },
  CONTABILIDAD: {
    TITULO_PAGINA: 'LISTADO DE CONTABILIDAD',
    TITULO_MENU: 'Contabilidad',
  },
  FACTURACION_CONFIRMACION_PAGOS: {
    TITULO_PAGINA: 'LISTADO DE FACTURACIÓN',
    TITULO_MENU: 'Facturación de confirmación de pagos',
  },
  GERENTE_FINANCIERO: {
    DATOS_DEL_SERVICIO: 'DATOS DEL SERVICIO',
  },
  CONTROL_VENTA_TIQUETERAS: {
    GESTION_VENTA_DE_TIQUETERAS: 'Gestión de Venta de Tiqueteras',
    CONTROL_VENTA_DE_TIQUETERAS: 'Control de ventas de Tiqueteras',
    VER_TIQUETERAS_REGISTRADAS: 'Ver Tiqueteras Registradas',
    VER_MODIFICAR_TIQUETERAS_REGISTRADAS:
      'Ver/Modificar Tiqueteras Registradas',
  },
}

export const CLASIFICACION_SERVICIO = {
  SERVICIO: 'servicio',
  PAQUETE: 'paquete',
}

export const NOMBRE_REFERENCIAS = {
  CIE10: 'CIE10',
  FACTURA_GENERADA: 'FACTURA GENERADA',
  FECHA_EMISION: 'fechaEmision',
  DOCUMENTACION_ELECTRONICA: {
    DOCUMENTO_GENERADO: 'DOCUMENTO GENERADO',
  },
}

export const NOMBRES_MEDIOS_PAGO = {
  USO_DE_SALDO_PORTAL_CLIENTE: 'USO DE SALDO PORTAL CLIENTE',
  USO_DE_SALDO_CUENTA_DEL_CLIENTE: 'USO DE SALDO CUENTA DEL CLIENTE',
  TIQUETERAS: 'TIQUETERAS',
  BONOS: 'BONOS',
}

export const TEXTO_AGENDAS = {
  EN_SALA: 'EN SALA',
  LABORATORIO_CLINICO: 'LABORATORIO CLINICO',
  VACUNACION: 'VACUNACIÓN',
}

export const CLASES_DE_FACTURACION = {
  CONTADO: 'CONTADO',
  CREDITO: 'CRÉDITO',
}

export const TIPO_REPORTE = {
  RESUMEN_POR_ENTIDAD: 1,
  DETALLE_DE_ATENCION_POR_PACIENTE: 2,
}

export const GRUPO_PRECIOS = {
  ALIADOS: 'ALIADOS',
}

export const ALERTAS = {
  EDAD_MAXIMA: 'La edad máxima para el registro es de 99 años.',
}
