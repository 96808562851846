import React, {useEffect} from 'react'
import {TEXTO_VISUAL} from '../../constantes'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {TablaConfirmacionPagos} from '../../componentes/ConfirmacionPagosComponentes'
import {AgregarEmpresasACache} from '../../cache/servicios-cache'

const ConfirmacionDePagos = () => {
  useEffect(() => {
    const agregarEmpresas = async () => {
      await AgregarEmpresasACache()
    }
    agregarEmpresas()
  }, [])

  return (
    <ContenedorPagina
      tituloPagina={TEXTO_VISUAL.CONFIRMACION_PAGOS.TITULO_PAGINA}
      estilosContenedor={'w-95%'}
    >
      <TablaConfirmacionPagos />
    </ContenedorPagina>
  )
}

export default ConfirmacionDePagos
