import {useContext, useEffect, useState} from 'react'
import ComponenteConjuntoDeFiltros from '../../componentes/Filtros/ComponenteConjuntoDeFiltros'
import {ContenedorPagina} from '../../componentes/paginas/ContenedorPagina'
import {
  agregarFormatoFecha,
  aplicarFormatoMoneda,
} from '../../utilidades/funcionesComunes'
import {
  EncabezadoFondo,
  TablaConPaginacionYFiltros,
} from '../../componentes/Tablas'
import {BotonSimple} from '../../componentes'
import {ContextApplication} from '../../contexto'
import FormularioAsignacionEntrega from '../../componentes/VentaTiqueteras/FormularioAsignacionEntrega'
import {ObtenerTiqueterasPendientesGestion} from '../../microservicios'
import {
  AgregarEmpresasACache,
  ObtenerCiudadPorId,
  ObtenerEmpresaPorId,
} from '../../cache/servicios-cache'
import moment from 'moment'
import {FORMATOS_STANDARD, ROLES_APP} from '../../constantes'
import AlertaSimple from '../../componentes/Visuales/AlertaSimple'
import clsx from 'clsx'
import InfoFacturaImpresion from '../../componentes/Factura/InfoFacturaImpresion'
import {useMsal} from '@azure/msal-react'
import {fnObtenerInformacionSessionOLocalStorage} from '../../utilidades/window.utils'
import {ObtenerCiudadPorNombre} from '../../cache/servicios-cache/Ciudades'

const ControlVentasTiqueteras = () => {
  const [datos, setDatos] = useState({datos: [], paginacion: {}})
  const [cambioBusqueda, setCambioBusqueda] = useState(false)
  const contextoAplicacion = useContext(ContextApplication)
  const {accounts} = useMsal()
  const rolUsuario = accounts?.[0]?.idTokenClaims?.roles?.[0] ?? ''
  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      fechaInicio: null,
      fechaFin: null,
      nit: null,
      verificacionNit: null,
      razonSocial: null,
      numeroLibreta: null,
      consecutivoInicial: null,
      consecutivoFinal: null,
      numeroFactura: null,
      ciudadId: null,
      sedeId: null,
    },
  }
  const [valoresFiltros, setValoresFiltros] = useState(filtrosIniciales)
  const manejarCambioEnFiltro = (e) => {
    setValoresFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }

  const preparacionDato = async (dato) => {
    const ciudad = await ObtenerCiudadPorId(dato.ciudadId)
    const empresa = await ObtenerEmpresaPorId(dato.empresaId)

    return {
      ...dato,
      empresa: !!empresa ? {nombre: empresa.Nombre, nit: empresa.Nit} : null,
      ciudadNombre: ciudad?.[0]?.Nombre,
      fechaPago: moment(dato.fechaPago).isValid()
        ? moment(dato.fechaPago).format(
            FORMATOS_STANDARD.DD_MM_YYYY_SEP_BARRA_DIAGONAL
          )
        : '',
    }
  }

  const obtenerInformacion = async () => {
    const response = await ObtenerTiqueterasPendientesGestion(valoresFiltros)
    const datosPromesas =
      response?.data?.datos?.map((dato) => preparacionDato(dato)) ?? []
    const datosPreparados = await Promise.all(datosPromesas)

    setDatos({
      datos: datosPreparados,
      paginacion: response?.data?.paginacion ?? {},
    })
  }

  const ejecutarAsignar = (row) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <FormularioAsignacionEntrega
          informacion={row}
          refrescarDatos={obtenerInformacion}
        />
      ),
      botones: [],
    })
  }

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-15%',
      titulo: 'Fecha inicio',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      fechaMinima: valoresFiltros.filtros.fechaFin
        ? moment(valoresFiltros.filtros.fechaFin)
            .add(-30, 'days')
            .format('YYYY-MM-DD')
        : null,
      fechaMaxima: valoresFiltros.filtros.fechaFin
        ? moment(valoresFiltros.filtros.fechaFin).format('YYYY-MM-DD')
        : null,
      nombre: 'fechaInicio',
      formato: 'input',
      valor: valoresFiltros.filtros.fechaInicio,
    },
    {
      id: 2,
      estilos: 'w-15%',
      titulo: 'Fecha fin',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      fechaMinima: valoresFiltros.filtros.fechaInicio
        ? moment(valoresFiltros.filtros.fechaInicio).format('YYYY-MM-DD')
        : null,
      fechaMaxima: valoresFiltros.filtros.fechaInicio
        ? moment(valoresFiltros.filtros.fechaInicio)
            .add(30, 'days')
            .format('YYYY-MM-DD')
        : null,
      nombre: 'fechaFin',
      formato: 'input',
      valor: valoresFiltros.filtros.fechaFin,
    },
    {
      id: 3,
      estilos: 'w-15%',
      titulo: 'NIT',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nit',
      formato: 'input',
      valor: valoresFiltros.filtros.nit,
    },
    {
      id: 4,
      estilos: 'w-20%',
      titulo: 'Razón social',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'razonSocial',
      formato: 'input',
      valor: valoresFiltros.filtros.razonSocial,
    },
    {
      id: 5,
      estilos: 'w-15%',
      titulo: 'Número de factura',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'numeroFactura',
      formato: 'input',
      valor: valoresFiltros.filtros.numeroFactura,
    },
  ]

  const columnas = [
    {
      accessor: 'id',
      className: 'w-0 hidden',
      headerStyle: '',
      disableFilters: true,
      cellStyles: '',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Fecha'} />,
      accessor: 'fechaPago',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-l-lg pl-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'NIT'} paddingFondoClaro={''} />,
      accessor: 'empresa.nit',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => (
        <EncabezadoFondo titulo={'Razón social'} paddingFondoClaro={''} />
      ),
      accessor: 'empresa.nombre',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Ciudad'} />,
      accessor: 'ciudadNombre',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Tipo de Tiquetera'} />,
      accessor: 'nombreTiquetera',
      className: 'w-15% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Cantidad'} />,
      accessor: 'cantidad',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
    },
    {
      Header: () => <EncabezadoFondo titulo={'Nº Factura'} />,
      accessor: 'numeroFactura',
      className: 'w-15% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => {
        return !row.original.numeroFactura ? (
          <AlertaSimple mensaje={'No generada'} />
        ) : (
          <InfoFacturaImpresion
            numeroFactura={row.original.numeroFactura}
            urlDocumentoFactura={row.original.urlDocumentoFactura}
          />
        )
      },
    },
    {
      Header: () => <EncabezadoFondo titulo={'Valor'} />,
      accessor: 'valor',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 h-auto flex justify-center items-center break-all',
      Cell: ({row}) => aplicarFormatoMoneda(row.original.valor),
    },
    {
      Header: () => (
        <EncabezadoFondo
          titulo={''}
          estilos="w-full flex justify-center items-center my-1 mx-0.5 rounded-md text-14px"
        />
      ),
      accessor: 'acciones',
      className: 'w-10% uppercase',
      headerStyle: 'bg-cendiatra-verde-7 text-white h-16 rounded-r-lg pr-1',
      disableFilters: true,
      cellStyles:
        'text-13px text-cendiatra-gris-1 flex justify-center items-center break-all !bg-red',
      Cell: ({row}) => (
        <BotonSimple
          texto={'Asignar'}
          estilosBoton={clsx(
            `w-24 h-8 text-white bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`,
            !row.original.numeroFactura &&
              'bg-cendiatra-gris-placeholder pointer-events-none',
            !!row.original.numeroFactura && 'bg-btnBg cursor-pointer'
          )}
          funcion={() => ejecutarAsignar(row.original)}
          deshabilitado={!row.original.numeroFactura}
        />
      ),
    },
  ]

  useEffect(() => {
    AgregarEmpresasACache(true).then(() => setCambioBusqueda(true))
  }, [])

  useEffect(() => {
    if (!rolUsuario) return

    const configurarCiudad = async () => {
      let ciudadId = null
      let sedeId = null
      if (rolUsuario === ROLES_APP.GERENTE_COMERCIAL) {
        const ciudad = await ObtenerCiudadPorNombre('BOGOTÁ')
        ciudadId = +(ciudad?.Id ?? 0)
      } else {
        sedeId = fnObtenerInformacionSessionOLocalStorage('sedeId', rolUsuario)
      }

      setValoresFiltros((prv) => ({
        ...prv,
        filtros: {
          ...prv.filtros,
          ciudadId,
          sedeId,
        },
      }))
    }

    configurarCiudad()
  }, [rolUsuario])

  useEffect(() => {
    if (
      !cambioBusqueda ||
      (!valoresFiltros.filtros.ciudadId && !valoresFiltros.filtros.sedeId)
    )
      return
    setCambioBusqueda(false)
    obtenerInformacion()
  }, [cambioBusqueda])

  return (
    <ContenedorPagina tituloPagina={'Control de venta de Tiqueteras'}>
      <div className="w-full">
        <div className="w-full flex justify-center">
          <span className="text-cendiatra-rojo-1 font-bold">
            El filtro se puede aplicar con máximo de un mes
          </span>
        </div>
        <div>
          <ComponenteConjuntoDeFiltros
            filtrosPorCrear={filtrosExternos}
            funcionBoton={obtenerInformacion}
          />
        </div>
        <div>
          <TablaConPaginacionYFiltros
            data={datos.datos}
            columns={columnas}
            paginacionBackend
            parametrosPaginacionConBackEnd={datos.paginacion}
            filasPorPagina={10}
            maximoDePaginasparaMostrar={10}
            funcionCambiarPagina={(numeroPagina) => {
              setValoresFiltros((prv) => ({...prv, pagina: numeroPagina}))
              setCambioBusqueda(true)
            }}
            usarSort={false}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default ControlVentasTiqueteras
