import IconoAlerta from '../../imagenes/alerta.svg'
const AlertaSimple = ({mensaje}) => {
  return (
    <div className="w-full flex flex-wrap gap-2 bg-cendiatra-gris-5 p-2 rounded">
      <img src={IconoAlerta} className='w-5' alt="warning" />
      <span className='font-bold text-xs text-red-800 normal-case'>{mensaje}</span>
    </div>
  )
}

export default AlertaSimple
