import {dbComun} from '../bases-datos/dbComun'

export const ObtenerEstadosCiviles = async () => {
  return Promise.resolve(dbComun.table('EstadoCivil').toArray())
}

export const ObtenerEscolaridades = async () => {
  return Promise.resolve(dbComun.table('Escolaridad').toArray())
}

export const ObtenerRazas = async () => {
  return Promise.resolve(dbComun.table('Raza').toArray())
}

export const ObtenerSexos = async () => {
  return Promise.resolve(dbComun.table('Sexo').toArray())
}

export const ObtenerSexoPorId = async (id) => {
  return Promise.resolve(dbComun.table('Sexo').get({Id: id}))
}

export const ObtenerZonas = async () => {
  return Promise.resolve(dbComun.table('Zona').toArray())
}

export const ObtenerEstratos = async () => {
  return Promise.resolve(dbComun.table('Estrato').toArray())
}

export const ObtenerIndicativos = async () => {
  return Promise.resolve(dbComun.table('IndicativoTelefono').toArray())
}

export const ObtenerFacturarA = async () => {
  return Promise.resolve(dbComun.table('FacturarA').toArray())
}

export const ObtenerTiposDeExamen = async () => {
  return Promise.resolve(dbComun.table('TipoExamen').toArray())
}

export const ObtenerActividadEconomica = async () => {
  return Promise.resolve(dbComun.table('ActividadEconomica').toArray())
}

export const ObtenerTiposPoblacion = async () => {
  return Promise.resolve(dbComun.table('TipoPoblacion').toArray())
}

export const ObtenerTiposRetencion = async () => {
  return Promise.resolve(dbComun.table('TipoRetencion').toArray())
}
