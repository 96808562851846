import React, {useEffect, useState, useContext, useRef} from 'react'
import {
  Input,
  ErroresInput,
  InputMoneda,
  ListaDesplegable,
  InputNumerico,
} from '../inputs'
import BotonAgregar from '../../imagenes/addButton.png'
import BotonQuitar from '../../imagenes/removeButton.png'
import BotonConImagen from '../BotonConImagen'
import {Controller, useForm} from 'react-hook-form'
import {
  regexCorreoElectronico,
  regexValidarNit,
  regexNombreEmpresaHC,
  regexTextoCaracteresBasicos,
  TIPOS_RETENCIONES,
  TIPOS_RETENCION_STRING,
  CIUDADES_IDS,
  TIPOS_FACTURACION,
} from '../../constantes'
import {aplicarFormatoMoneda} from '../../utilidades'
import {BotonSimple} from '../Botones'
import {FachadaGuardarDatosFacturacionPago} from '../../microservicios'
import {ContextApplication} from '../../contexto'
import clsx from 'clsx'
import {TEXTO_VISUAL} from '../../constantes'
import {v4 as uuidv4} from 'uuid'
import CampoFiltrarEspañol from '../CampoFiltrarEspañol'
import {
  ObtenerEmpresasPorNit,
  FiltrarCiudadPorNombre,
} from '../../cache/servicios-cache'
import {BotonOpcion} from '../Botones'
import {ObtenerTiposRetencion} from '../../cache/servicios-cache/Referencia'

const FormularioDatosDeLosServicios = ({
  valorFactura,
  funcionCerrar,
  valoresFormulario,
  servicios,
  refrescarDatos,
}) => {
  const {
    control,
    trigger,
    reset,
    getValues,
    setValue,
    setError,
    formState: {errors},
  } = useForm({
    defaultValues: {},
    mode: 'onChange',
  })

  const datosServicioPorDefecto = {
    cantidad: '',
    id: '',
    nombreServicio: '',
    datosServicio: {},
    valorServicio: '',
    totalServicios: '',
  }
  const datosRetencionesPorDefecto = {
    tipoRetencionId: 0,
    informacionMunicipio: {},
    nombreMunicipio: '',
    tarifa: '',
    valorRetencion: '',
  }

  const contextoAplicacion = useContext(ContextApplication)

  const [datosServicioParaAgregar, setDatosServicioParaAgregar] = useState(
    datosServicioPorDefecto
  )
  const [datosRetencionesParaAgregar, setDatosRetencionesParaAgregar] =
    useState(datosRetencionesPorDefecto)

  const [serviciosFactura, setServiciosFactura] = useState([])

  const [valorTotal, setValorTotal] = useState(0)

  const [valorTotalRentenciones, setValorTotalRentenciones] = useState(0)

  const totalServiciosRef = useRef(0)

  const [mostrarErrorValoresDiferentes, setMostrarErrorValoresDiferentes] =
    useState(false)

  const [empresasFiltradas, setEmpresasFiltradas] = useState([])

  const [recargarCuentaCliente, setRecargarCuentaCliente] = useState(false)

  const [tiposRetenciones, setTiposRetenciones] = useState([])

  const [ocultarMunicipio, setOcultarMunicipio] = useState(false)

  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])

  const [retencionesAFacturar, setRetencionesAFacturar] = useState([])

  const [empresaTipoCredito, setEmpresaTipoCredito] = useState(false)

  const [noExisteLaEmpresa, setNoExisteLaEmpresa] = useState(false)

  const sedeId = JSON.parse(sessionStorage.getItem('sedeId'))

  useEffect(() => {
    const obtenerInformacionTiposRetencion = async () => {
      const tiposRetencion = await ObtenerTiposRetencion()
      setTiposRetenciones(tiposRetencion)
    }
    obtenerInformacionTiposRetencion()
  }, [])

  useEffect(() => {
    if (valoresFormulario) {
      reset(valoresFormulario)
      setServiciosFactura(
        servicios.map((servicio) => {
          return {
            cantidad: servicio.cantidad,
            nombreServicio: servicio.nombreServicio,
            valorServicio: servicio.valor,
            totalServicios:
              parseFloat(servicio.cantidad) * parseFloat(servicio.valor),
          }
        })
      )
    }
  }, [])

  useEffect(() => {
    const validarTipoRetencion = () => {
      setOcultarMunicipio(
        +datosRetencionesParaAgregar.tipoRetencionId ===
          TIPOS_RETENCIONES.RETENCION_RENTA ||
          +datosRetencionesParaAgregar.tipoRetencionId ===
            TIPOS_RETENCIONES.RETENCION_IVA
      )
    }
    validarTipoRetencion()
  }, [datosRetencionesParaAgregar.tipoRetencionId])

  const objetoVacio = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }
  const formularioTieneErrores = objetoVacio(errors)

  useEffect(() => {
    trigger()
  }, [])

  const cerrarModal = (modalName) => {
    contextoAplicacion[modalName]({
      abierto: false,
      titulo: '',
      contenido: <></>,
      botones: [],
    })
  }

  const mostrarModalConfirmacion = () => {
    contextoAplicacion.setModal2({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <div className={`w-full flex justify-center items-center flex-wrap`}>
            <span className="w-full text-center text-2xl font-bold text-cendiatra mb-10 normal-case">
              ¿Está de acuerdo con los datos?
            </span>
            <span className="w-full text-center text-lg font-bold text-cendiatra mb-10 normal-case">
              Esta acción no se puede deshacer
            </span>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Si',
          click: () => {
            cerrarModal('setModal2')
            guardarInformacionDatosServicios()
          },
        },
        {
          nombre: 'No',
          click: () => {
            cerrarModal('setModal2')
          },
        },
      ],
      clasesAdicioneles:
        'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
    })
  }

  const guardarInformacionDatosServicios = async () => {
    if (
      +valorTotalRentenciones > +valorFactura.valor &&
      recargarCuentaCliente
    ) {
      return contextoAplicacion.setModal2({
        abierto: true,
        titulo: '',
        contenido: (
          <>
            <div
              className={`w-full flex justify-center items-center flex-wrap`}
            >
              <span className="w-full text-center text-2xl font-bold text-cendiatra-rojo-1 mb-10">
                Error de datos
              </span>
              <span className="w-full text-center text-lg font-bold text-cendiatra-gris-2 mb-10 normal-case">
                El valor total de las retenciones supera el valor de la
                consignación
              </span>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal('setModal2')
            },
          },
        ],
        clasesAdicioneles:
          'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
      })
    }

    const valoresFormulario = getValues()

    if (
      parseFloat(valorFactura.valor) !== parseFloat(valorTotal) &&
      !recargarCuentaCliente
    ) {
      return contextoAplicacion.setModal2({
        abierto: true,
        titulo: '',
        contenido: (
          <>
            <div
              className={`w-full flex justify-center items-center flex-wrap`}
            >
              <span className="w-full text-center text-2xl font-bold text-cendiatra-rojo-1 mb-10">
                Error de datos
              </span>
              <span className="w-full text-center text-lg font-bold text-cendiatra-gris-2 mb-10 normal-case">
                El valor de los servicios no corresponde con el pago
              </span>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal('setModal2')
            },
          },
        ],
        clasesAdicioneles:
          'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
      })
    }

    const payload = {
      pagoId: valorFactura?.id,
      nit: valoresFormulario?.nit,
      empresaId: valoresFormulario?.empresaId,
      razonSocial: valoresFormulario?.razonSocial,
      correo: valoresFormulario?.correo,
      sedeId: sedeId,
      recargarSaldo: recargarCuentaCliente,
      servicios: !recargarCuentaCliente
        ? serviciosFactura?.map((servicio) => {
            return {
              cantidad: servicio.cantidad,
              nombreServicio: servicio.nombreServicio,
              valor: servicio.valorServicio,
            }
          })
        : [],
      retenciones: recargarCuentaCliente
        ? retencionesAFacturar?.map((retencion) => {
            return {
              municipioId: retencion?.informacionMunicipio?.id ?? 0,
              tipoRetencionId: +retencion?.tipoRetencionId,
              porcentaje: retencion?.tarifa,
              valor: retencion?.valorRetencion,
            }
          })
        : [],
    }
    try {
      const respuesta = await FachadaGuardarDatosFacturacionPago(payload)

      if (respuesta.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: '',
          contenido: (
            <div className="w-full flex justify-center items-center text-center flex-wrap">
              <span className="w-full font-bold text-3xl mb-12 normal-case">
                Guardado exitoso
              </span>
              <span className="w-full normal-case text-xl">
                La confirmación del pago fue guardada correctamente.
              </span>
            </div>
          ),

          botones: [
            {
              nombre: 'Cerrar',
              click: () => funcionCerrar(),
            },
          ],
        })
        if (recargarCuentaCliente) {
          window.open(respuesta.data, '_blank')
        }
        return refrescarDatos()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const valorCantidad = parseFloat(datosServicioParaAgregar.cantidad)
    const valorServicio = parseFloat(datosServicioParaAgregar.valorServicio)

    if (isNaN(valorCantidad) && isNaN(valorServicio)) {
      totalServiciosRef.current = ''
    } else {
      setDatosServicioParaAgregar((prev) => {
        return {
          ...prev,
          totalServicios: valorCantidad * valorServicio,
        }
      })

      totalServiciosRef.current = valorCantidad * valorServicio
    }
  }, [
    datosServicioParaAgregar.cantidad,
    datosServicioParaAgregar.valorServicio,
  ])

  useEffect(() => {
    const obtenerSumatoriaServicios = () => {
      const sumatoriaValorServicios = serviciosFactura.reduce(
        (total, servicio) => {
          return total + servicio.totalServicios
        },
        0
      )
      setValorTotal(sumatoriaValorServicios)
    }
    obtenerSumatoriaServicios()
  }, [serviciosFactura])

  useEffect(() => {
    const obtenerSumatoriaRenta = () => {
      const sumatoriaValorRetenciones = retencionesAFacturar.reduce(
        (suma, item) => suma + (item.valorRetencion || 0),
        0
      )
      setValorTotalRentenciones(sumatoriaValorRetenciones)
    }
    obtenerSumatoriaRenta()
  }, [retencionesAFacturar.length])

  const actualizarServicioParaAgregar = (nombre, e) => {
    setDatosServicioParaAgregar((prev) => {
      return {
        ...prev,
        [nombre]: e.target?.value ? e.target?.value : e?.floatValue,
      }
    })
  }

  const actualizarRetencionesParaAgregar = (nombre, e) => {
    setDatosRetencionesParaAgregar((prev) => {
      return {
        ...prev,
        [nombre]: e.target?.value ? e.target?.value : e?.floatValue,
      }
    })
  }

  const agregarServicioFactura = () => {
    if (
      datosServicioParaAgregar?.cantidad &&
      datosServicioParaAgregar?.nombreServicio &&
      datosServicioParaAgregar?.valorServicio &&
      !errors.nombreServicio
    ) {
      const servicioConId = {...datosServicioParaAgregar, id: uuidv4()}
      setServiciosFactura((prev) => [...prev, servicioConId])
      setDatosServicioParaAgregar(datosServicioPorDefecto)
    }
  }

  const validarExistenciaMunicipio = () => {
    if (ocultarMunicipio) return true

    return Boolean(
      Object.keys(datosRetencionesParaAgregar?.informacionMunicipio).length
    )
  }

  const agregarRetencion = () => {
    if (
      validarExistenciaMunicipio() &&
      datosRetencionesParaAgregar?.tipoRetencionId &&
      datosRetencionesParaAgregar?.valorRetencion &&
      datosRetencionesParaAgregar?.tarifa
    ) {
      const yaExisteRetencion = validarRetencionYaExiste(
        datosRetencionesParaAgregar?.tipoRetencionId,
        datosRetencionesParaAgregar?.informacionMunicipio?.id
      )

      if (yaExisteRetencion) return

      setRetencionesAFacturar((prev) => [...prev, datosRetencionesParaAgregar])
      setDatosRetencionesParaAgregar(datosRetencionesPorDefecto)
      setCiudadesFiltradas([])
    }
  }

  const validarRetencionYaExiste = (tipoRetencionId, municipioId = null) => {
    if (
      +tipoRetencionId === TIPOS_RETENCIONES.RETENCION_RENTA ||
      +tipoRetencionId === TIPOS_RETENCIONES.RETENCION_IVA
    ) {
      return Boolean(
        retencionesAFacturar.some(
          (elemento) => elemento.tipoRetencionId === tipoRetencionId
        )
      )
    }

    if (
      +tipoRetencionId === TIPOS_RETENCIONES.RETENCION_ICA ||
      +tipoRetencionId === TIPOS_RETENCIONES.SOBRETASA_BOMBERIL
    ) {
      return Boolean(
        retencionesAFacturar.some(
          (item) =>
            +item.tipoRetencionId === +tipoRetencionId &&
            +item.informacionMunicipio?.id === +municipioId
        )
      )
    }
  }

  const eliminarServicios = (id) => {
    const nuevoArreglo = serviciosFactura.filter(
      (servicio) => servicio.id !== id
    )
    setServiciosFactura(nuevoArreglo)
  }

  const eliminarRetencion = (retencionId, municipioId) => {
    const nuevoArreglo = retencionesAFacturar.filter((retencion) => {
      if (municipioId) {
        return (
          +retencion.tipoRetencionId !== +retencionId ||
          +retencion.informacionMunicipio?.id !== +municipioId
        )
      }
      return +retencion.tipoRetencionId !== +retencionId
    })

    setRetencionesAFacturar(nuevoArreglo)
  }

  const manejarCambioNit = async (valor) => {
    setValue('nit')
    setError('nit', {type: 'require', message: ''})

    if (valor.length >= 6) {
      contextoAplicacion.setIsLoading(true)
      const empresasFiltradas = await ObtenerEmpresasPorNit(valor)
      setNoExisteLaEmpresa(!empresasFiltradas.length)
      setEmpresasFiltradas(
        empresasFiltradas.map((empresa) => {
          return {
            id: empresa.Id,
            filtro: empresa.Nit,
            razonSocial: empresa.Nombre,
            correo: empresa.Correo,
            tipoFacturacion: empresa.TipoFacturacion,
          }
        })
      )
      contextoAplicacion.setIsLoading(false)
    } else {
      setNoExisteLaEmpresa(false)
      setEmpresasFiltradas([])
    }
  }
  const filtrarPorCiudad = async (e) => {
    if (Object.keys(datosRetencionesParaAgregar.informacionMunicipio).length) {
      setDatosRetencionesParaAgregar((prev) => {
        return {
          ...prev,
          informacionMunicipio: {},
        }
      })
    }
    if (e.target.value.length >= 3) {
      let ciudadesParaMostrar = await FiltrarCiudadPorNombre(e.target.value)

      if (
        +datosRetencionesParaAgregar.tipoRetencionId ===
        TIPOS_RETENCIONES.SOBRETASA_BOMBERIL
      ) {
        ciudadesParaMostrar = ciudadesParaMostrar.filter(
          (ciudad) => ciudad.Id !== CIUDADES_IDS.BOGOTA
        )
      }
      setCiudadesFiltradas(
        ciudadesParaMostrar.map((ciudad) => {
          return {
            id: ciudad.Id,
            filtro: ciudad.Nombre,
          }
        })
      )
    } else {
      setCiudadesFiltradas([])
    }
  }

  const manejarCiudadSeleccionada = (ciudad) => {
    setDatosRetencionesParaAgregar((prev) => {
      return {
        ...prev,
        informacionMunicipio: ciudad,
        nombreMunicipio: ciudad.filtro,
      }
    })
  }
  const mostrarModalEmpresaCredito = (tipoFacturacion) => {
    if (
      tipoFacturacion.toUpperCase() === TIPOS_FACTURACION.CREDITO.toUpperCase()
    ) {
      setEmpresaTipoCredito(true)
      setRecargarCuentaCliente(false)
      return contextoAplicacion.setModal2({
        abierto: true,
        titulo: '',
        contenido: (
          <>
            <div
              className={`w-full flex justify-center items-center flex-wrap`}
            >
              <span className="w-full text-center text-2xl font-bold text-cendiatra mb-10 normal-case">
                Este cliente es crédito
              </span>
              <span className="w-full text-center text-lg font-bold text-cendiatra mb-10 normal-case">
                No se puede realizar recargas de saldo
              </span>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: () => {
              cerrarModal('setModal2')
            },
          },
        ],
        clasesAdicioneles:
          'w-1/3 pt-0 pb-0 px-10 bg-cendiatra-gris-5 rounded-2xl',
      })
    } else {
      setEmpresaTipoCredito(false)
    }
  }

  return (
    <>
      <div
        className={`w-full ${
          mostrarErrorValoresDiferentes ? 'hidden' : 'flex'
        } justify-center items-center flex-wrap -mt-10`}
      >
        <div className="w-4/10 flex items-center text-3xl text-center font-bold normal-case">
          {!valoresFormulario ? (
            <>
              Confirmar recarga por <br /> Consignación Bancaria
            </>
          ) : (
            'Datos de los servicios'
          )}
        </div>
        <div
          className={`w-full flex jusitfy-center items-center border rounded-2xl flex-wrap p-4 mt-5 text-center ${
            valoresFormulario ? 'pointer-events-none' : ''
          }`}
        >
          <span className="w-full text-center normal-case">
            Datos del cliente
          </span>
          <div className="w-full flex justify-evenly items-center mt-3">
            <Controller
              name="nit"
              control={control}
              rules={{
                required: true,
              }}
              render={({field: {onChange, value}}) => (
                <CampoFiltrarEspañol
                  estilosPersonalizados={
                    'w-3/12 text-left text-cendiatra-gris-3'
                  }
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    {
                      'border border-cendiatra-rojo-1':
                        errors.nit && !valoresFormulario,
                      'border border-cendiatra':
                        !errors.nit && !valoresFormulario,
                      'border-none': valoresFormulario,
                    }
                  )}
                  tipo={'number'}
                  titulo={'NIT*'}
                  placeholder={'Autocompletar'}
                  valorDelCampoFiltro={value}
                  informacionFiltrada={empresasFiltradas}
                  desactivarOtroFiltro={false}
                  handleChange={(evt) => manejarCambioNit(evt.target.value)}
                  handleOptionChange={(evt) => {
                    onChange(evt.filtro)
                    setValue('nit', evt?.filtro ?? '')
                    setValue('razonSocial', evt?.razonSocial ?? '')
                    setValue('correo', evt?.correo ?? '')
                    setValue('empresaId', evt?.id)
                    mostrarModalEmpresaCredito(evt.tipoFacturacion)
                  }}
                />
              )}
            />
            <Controller
              name="razonSocial"
              control={control}
              rules={{
                required: false,
                pattern: regexNombreEmpresaHC,
              }}
              render={({field: {value, onChange}}) => (
                <Input
                  onChange={onChange}
                  estilosContenedor={'w-3/12 text-left normal-case'}
                  estilosInput={clsx(
                    'appearance-none rounded relative border-cendiatra block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    {
                      'border bg-cendiatra-gris-2 pointer-events-none text-white':
                        !valoresFormulario,
                      'text-cendiatra-gris-3': valoresFormulario,
                    }
                  )}
                  tipo={'text'}
                  placeholder={'Descripción'}
                  titulo={'Razón social*'}
                  valor={value}
                  tabIndex={'-1'}
                />
              )}
            />
            <Controller
              name="correo"
              control={control}
              rules={{
                required: false,
                pattern: regexCorreoElectronico,
              }}
              render={({field: {value, onChange}}) => (
                <div className="w-4/12 flex items-center justify-center flex-wrap">
                  <Input
                    onChange={onChange}
                    estilosContenedor={'w-full text-left normal-case'}
                    estilosInput={clsx(
                      'appearance-none rounded relative border-cendiatra block w-full p-1.5 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      {
                        'border bg-cendiatra-gris-2 pointer-events-none text-white':
                          !valoresFormulario,
                        'text-cendiatra-gris-3': valoresFormulario,
                      }
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Correo*'}
                    valor={value}
                    tabIndex={'-1'}
                  />
                  <ErroresInput
                    erroresFormulario={errors}
                    nombre={'correo'}
                    tipoError={'pattern'}
                    mensaje={'Ej: ejemplo@ejemplo.com'}
                  />
                </div>
              )}
            />
          </div>
          {noExisteLaEmpresa ? (
            <span className="w-full text-cendiatra-rojo-1 text-center mt-2 normal-case">
              La empresa no existe en GATO
            </span>
          ) : null}
        </div>
        {!valoresFormulario ? (
          <div className="w-full flex items-center justify-center flex-wrap">
            <span className="w-full text-center mt-3 normal-case text-cendiatra font-extrabold text-lg">
              {recargarCuentaCliente ? 'Valor consignación' : 'Valor del pago'}:{' '}
              {aplicarFormatoMoneda(valorFactura.valor)}
            </span>
            <div className="w-full flex justify-center items-center mt-3">
              <span className="w-auto felx justify-center items-center normal-case mr-5 text-xl text-cendiatra-gris-2">
                Recargar cuenta del cliente
              </span>
              <div
                className={clsx(
                  'w-20 flex justify-center items-center mt-2 pointer-events-none'
                  //  {
                  // 'pointer-events-none': empresaTipoCredito, COMENTADO TEMPORALMENTE POR PETICION DE CENDIATRA
                  //  }
                )}
              >
                <BotonOpcion
                  titulo={''}
                  estaActivo={recargarCuentaCliente}
                  funcionClick={() =>
                    setRecargarCuentaCliente(!recargarCuentaCliente)
                  }
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="w-full flex jusitfy-center items-center border rounded-2xl flex-wrap p-4  text-center">
          <span className="w-full text-center normal-case">
            {recargarCuentaCliente ? 'Retenciones' : 'Servicios a facturar'}
          </span>
          {!valoresFormulario && !recargarCuentaCliente ? (
            <div className="w-full flex justify-evenly items-center mt-3 flex-wrap">
              <Input
                onChange={(e) => actualizarServicioParaAgregar('cantidad', e)}
                estilosContenedor={'w-22% text-left normal-case'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                  {
                    'bg-cendiatra-gris-2 pointer-events-none':
                      noExisteLaEmpresa,
                  }
                )}
                tipo={'number'}
                placeholder={'Descripción'}
                titulo={'Cantidad'}
                valor={datosServicioParaAgregar.cantidad}
                nombre={'cantidad'}
                deshabilitado={noExisteLaEmpresa}
              />
              <Controller
                name="nombreServicio"
                control={control}
                rules={{
                  required: false,
                  pattern: regexTextoCaracteresBasicos,
                }}
                render={({field: {onChange}}) => (
                  <Input
                    funcion={(e) => {
                      onChange(e)
                      actualizarServicioParaAgregar('nombreServicio', e)
                    }}
                    estilosContenedor={'w-22%  text-left normal-case'}
                    estilosInput={clsx(
                      'appearance-none rounded relative block border w-full p-1.5 text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                      {
                        'border-cendiatra-rojo-1':
                          Object.keys(errors).includes('nombreServicio'),
                        'border-cendiatra':
                          !Object.keys(errors).includes('nombreServicio'),
                        'bg-cendiatra-gris-2 pointer-events-none':
                          noExisteLaEmpresa,
                      }
                    )}
                    tipo={'text'}
                    placeholder={'Descripción'}
                    titulo={'Servicio'}
                    valor={datosServicioParaAgregar.nombreServicio}
                    nombre={'nombreServicio'}
                    deshabilitado={noExisteLaEmpresa}
                  />
                )}
              />
              <InputMoneda
                estilosContenedor={'w-22% text-left -mt-2 normal-case'}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 placeholder-white rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                  {
                    'bg-cendiatra-gris-2 pointer-events-none':
                      noExisteLaEmpresa,
                  }
                )}
                titulo={'Valor'}
                valor={datosServicioParaAgregar.valorServicio}
                formatoVacio={true}
                placeholder={'Descripción'}
                onValueChange={(e) =>
                  actualizarServicioParaAgregar('valorServicio', e)
                }
                nombre={'valorServicio'}
                deshabilitado={noExisteLaEmpresa}
              />
              <div className="w-22% flex jusitfy-center items-center flex-wrap">
                <label className="w-full block text-13px leading-15px text-cendiatra-verde-2 my-5px text-left -mt-1 normal-case">
                  Total servicios
                </label>
                <span className="w-full text-left appearance-none  relative block p-1.5 text-cendiatra-gris-3  rounded-lg focus:outline-none   ">
                  {isNaN(totalServiciosRef.current)
                    ? '$'
                    : aplicarFormatoMoneda(totalServiciosRef.current)}
                </span>
              </div>
              <BotonConImagen
                ancho={'w-7% flex justify-center items-center'}
                imagen={BotonAgregar}
                textoAlternativo={'Agregar'}
                funcionAEjecutar={agregarServicioFactura}
                estilosImagen={'w-8 h-8'}
              />
              <div className="w-full flex justify-center items-center mt-1">
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'nombreServicio'}
                  tipoError={'pattern'}
                  mensaje={TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                />
              </div>
            </div>
          ) : null}

          {!valoresFormulario && recargarCuentaCliente ? (
            <div className="w-full flex justify-evenly items-center  flex-wrap">
              <ListaDesplegable
                funcion={null}
                estilosContenedor={clsx('text-left normal-case', {
                  'w-30%': ocultarMunicipio,
                  'w-22%': !ocultarMunicipio,
                })}
                estilosLista={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                  {
                    'bg-cendiatra-gris-1 pointer-events-none':
                      noExisteLaEmpresa,
                  }
                )}
                titulo={'Tipo de Retención'}
                opciones={tiposRetenciones}
                valor={datosRetencionesParaAgregar.tipoRetencionId}
                onChange={(e) =>
                  actualizarRetencionesParaAgregar('tipoRetencionId', e)
                }
                nombre={'retencion'}
                deshabilitado={noExisteLaEmpresa}
              />

              {!ocultarMunicipio ? (
                <CampoFiltrarEspañol
                  titulo={'Municipio'}
                  estilosPersonalizados={
                    'w-22% text-left normal-case text-cendiatra-gris-3'
                  }
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                    {
                      'bg-cendiatra-gris-2 pointer-events-none placeholder-white':
                        noExisteLaEmpresa,
                    }
                  )}
                  tipo={'text'}
                  valorDelCampoFiltro={
                    datosRetencionesParaAgregar.nombreMunicipio
                  }
                  informacionFiltrada={ciudadesFiltradas}
                  handleChange={(e) => {
                    filtrarPorCiudad(e)
                    actualizarRetencionesParaAgregar('nombreMunicipio', e)
                  }}
                  handleOptionChange={manejarCiudadSeleccionada}
                  desactivarOtroFiltro={noExisteLaEmpresa}
                  placeholder={'Autocompletar'}
                />
              ) : null}
              <InputNumerico
                estilosContenedor={clsx('text-left normal-case -mt-1.5', {
                  'w-30%': ocultarMunicipio,
                  'w-22%': !ocultarMunicipio,
                })}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                  {
                    'bg-cendiatra-gris-2 pointer-events-none placeholder-cendiatra-gris-4':
                      noExisteLaEmpresa,
                  }
                )}
                placeholder={'0.00'}
                titulo={'Tarifa'}
                deshabilitado={false}
                onValueChange={(e) =>
                  actualizarRetencionesParaAgregar('tarifa', e)
                }
                valor={datosRetencionesParaAgregar.tarifa}
                usarDecimal={true}
                deshabilitarTab={noExisteLaEmpresa}
              />
              <InputMoneda
                estilosContenedor={clsx('text-left normal-case -mt-1.5', {
                  'w-30%': ocultarMunicipio,
                  'w-22%': !ocultarMunicipio,
                })}
                estilosInput={clsx(
                  'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm ',
                  {
                    'bg-cendiatra-gris-2 pointer-events-none placeholder-cendiatra-gris-4':
                      noExisteLaEmpresa,
                  }
                )}
                titulo={'Valor Retención'}
                valor={datosRetencionesParaAgregar.valorRetencion}
                formatoVacio={true}
                placeholder={'$0.00'}
                onValueChange={(e) =>
                  actualizarRetencionesParaAgregar('valorRetencion', e)
                }
                nombre={'valorServicio'}
                deshabilitado={noExisteLaEmpresa}
              />

              <BotonConImagen
                ancho={'w-7% flex justify-center items-center'}
                imagen={BotonAgregar}
                textoAlternativo={'Agregar'}
                funcionAEjecutar={agregarRetencion}
                estilosImagen={'w-8 h-8'}
              />
              <div className="w-full flex justify-center items-center mt-1">
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'nombreServicio'}
                  tipoError={'pattern'}
                  mensaje={TEXTO_VISUAL.REGEX_MENSAJES.CARACTERES_PERMITIDOS_V1}
                />
              </div>
            </div>
          ) : null}

          {valoresFormulario ? (
            <div className="w-full flex justify-start items-center mt-5 text-sm text-left normal-case">
              <div className="w-22% pl-2">Cantidad</div>
              <div className="w-22% pl-6">Servicio</div>
              <div className="w-22% pl-8">Valor</div>
              <div className="w-22% pl-10">Total servicios</div>
            </div>
          ) : null}

          <span className="w-full border border-cendiatra-verde-3 mt-2"></span>
          <div
            className="w-full flex justify-evenly items-center mt-4 flex-wrap overflow-y-auto"
            style={{maxHeight: '160px'}}
          >
            {!recargarCuentaCliente || valoresFormulario
              ? serviciosFactura?.map((servicio) => (
                  <div className="w-full flex justify-center items-center my-0.5">
                    <span className="w-22% text-left mt-1 text-cendiatra-gris-1">
                      {servicio.cantidad}
                    </span>
                    <span className="w-22% text-left mt-1 text-cendiatra-gris-1">
                      {servicio.nombreServicio}
                    </span>
                    <span className="w-22% text-left mt-1 pl-4 text-cendiatra-gris-1">
                      {aplicarFormatoMoneda(servicio.valorServicio)}
                    </span>
                    <span className="w-22% text-left mt-1 pl-4 text-cendiatra-gris-1">
                      {aplicarFormatoMoneda(servicio.totalServicios)}
                    </span>
                    <div className="w-7%">
                      <BotonConImagen
                        ancho={`w-full ${
                          valoresFormulario ? 'hidden' : 'flex'
                        } justify-center items-center`}
                        imagen={BotonQuitar}
                        textoAlternativo={'Quitar'}
                        funcionAEjecutar={() => eliminarServicios(servicio.id)}
                        estilosImagen={'w-8 h-8'}
                      />
                    </div>
                  </div>
                ))
              : retencionesAFacturar?.map((retencion) => (
                  <div className="w-full flex justify-center items-center my-0.5">
                    <span className="w-30% text-left mt-1 text-cendiatra-gris-1">
                      {`${
                        TIPOS_RETENCION_STRING[+retencion?.tipoRetencionId]
                      } ${retencion?.nombreMunicipio ?? ''}`}
                    </span>
                    <span className="w-30% text-center mt-1 text-cendiatra-gris-1">
                      {retencion.tarifa.toFixed(2)}
                    </span>
                    <span className="w-30% text-center mt-1 pl-4 text-cendiatra-gris-1">
                      {aplicarFormatoMoneda(retencion.valorRetencion)}
                    </span>

                    <div className="w-7%">
                      <BotonConImagen
                        ancho={`w-full ${
                          valoresFormulario ? 'hidden' : 'flex'
                        } justify-center items-center`}
                        imagen={BotonQuitar}
                        textoAlternativo={'Agregar'}
                        funcionAEjecutar={() =>
                          eliminarRetencion(
                            retencion.tipoRetencionId,
                            retencion?.informacionMunicipio?.id
                          )
                        }
                        estilosImagen={'w-8 h-8'}
                      />
                    </div>
                  </div>
                ))}
          </div>
          <div className="w-full flex justify-end items-center text-cendiatra mt-2 mr-5 capitalize text-lg font-bold">
            Total{' '}
            {!recargarCuentaCliente || valoresFormulario
              ? aplicarFormatoMoneda(valorTotal)
              : aplicarFormatoMoneda(valorTotalRentenciones)}
          </div>
        </div>
        {!valoresFormulario && recargarCuentaCliente ? (
          <>
            <span className="w-full text-center mt-3 normal-case text-cendiatra font-extrabold text-lg">
              Valor a Recargar:{' '}
              {aplicarFormatoMoneda(
                +valorFactura.valor + +valorTotalRentenciones
              )}
            </span>
            <span className="w-full text-center mt-3 -mb-5 normal-case text-cendiatra-rojo-1 font-extrabold text-lg">
              Al guardar se recargará la cuenta del cliente
            </span>
          </>
        ) : null}
        <div className="w-full flex justify-evenly items-center mt-20">
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => funcionCerrar(valorFactura.id)}
            deshabilitado={false}
          />
          {!valoresFormulario ? (
            <BotonSimple
              texto={'Guardar'}
              estilosBoton={clsx(
                'hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-cover bg-no-repeat focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra',
                {
                  'bg-cendiatra-gris-2 pointer-events-none':
                    !formularioTieneErrores ||
                    (!serviciosFactura?.length && !recargarCuentaCliente),
                  'bg-btnBg':
                    formularioTieneErrores &&
                    (serviciosFactura?.length || recargarCuentaCliente),
                }
              )}
              funcion={
                recargarCuentaCliente
                  ? mostrarModalConfirmacion
                  : guardarInformacionDatosServicios
              }
              deshabilitado={
                !formularioTieneErrores ||
                (!serviciosFactura?.length && !recargarCuentaCliente)
              }
            />
          ) : null}
        </div>
      </div>
      {mostrarErrorValoresDiferentes ? (
        <div className={`w-full flex justify-center items-center flex-wrap`}>
          <span className="w-full text-center text-2xl font-bold text-cendiatra-rojo-1 mb-10">
            Error de datos
          </span>
          <span className="w-full text-center text-lg font-bold text-cendiatra-gris-2 mb-10 normal-case">
            El valor de los servicios no corresponde con el pago
          </span>
          <BotonSimple
            texto={'Regresar'}
            estilosBoton={`hover:opacity-80 items-center group relative w-48 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-btnBg bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra`}
            funcion={() => setMostrarErrorValoresDiferentes(false)}
            deshabilitado={false}
          />
        </div>
      ) : null}
    </>
  )
}

export default FormularioDatosDeLosServicios
