import imprimirVerde from '../../imagenes/iconoImprimirVerde.svg'

const InfoFacturaImpresion = ({numeroFactura, urlDocumentoFactura}) => {
  return (
    <div className="w-full flex items-center">
      <span className="w-9/12">{numeroFactura}</span>
      <div className="w-3/12">
        <img
          src={imprimirVerde}
          alt="imprimir"
          className="cursor-pointer"
          onClick={() => window.open(urlDocumentoFactura, '_blank')}
        />
      </div>
    </div>
  )
}

export default InfoFacturaImpresion
