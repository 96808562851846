export const encabezadosExportarEmpresas = [
  {label: 'Id Cliente', key: 'codigoInterno'},
  {label: 'Nit', key: 'nit'},
  {label: 'Cod_Verificación Nit', key: 'verificacionNit'},
  {label: 'Id', key: 'id'},
  {label: 'Razón Social', key: 'nombre'},
  {label: 'Dirección', key: 'direccion'},
  {label: 'Nombre Empresa Impresiones HC', key: 'nombreEmpresaHC'},
  {label: 'País', key: 'pais'},
  {label: 'Departamento', key: 'departamento'},
  {label: 'Ciudad', key: 'ciudad'},
  {label: 'Teléfono', key: 'telefono'},
  {label: 'Correo envió certificados', key: 'correo'},
  {label: 'Razón Social Factura', key: 'facturacionEmpresa.razonSocial'},
  {label: 'RUT Casilla 53', key: 'facturacionEmpresa.rut53'},
  {label: 'RUT Casilla 54', key: 'facturacionEmpresa.rut54'},
  {label: 'Código Postal', key: 'facturacionEmpresa.codigoPostal'},
  {
    label: 'Actividad económica principal',
    key: 'facturacionEmpresa.actividadEconomica',
  },
  {label: 'Matricula mercantil', key: 'facturacionEmpresa.matriculaMercantil'},
  {
    label: 'Correo para facturación',
    key: 'facturacionEmpresa.correoFacturacion',
  },
  {label: 'Valor contrato', key: 'facturacionEmpresa.valorContrato'},
  {label: 'Condiciones de pago', key: 'facturacionEmpresa.condicionPago'},
  {label: 'Descuento', key: 'facturacionEmpresa.descuento'},
  {label: 'Impuestos Gravables', key: 'facturacionEmpresa.impuesto'},
  {label: 'Dia de corte de facturación', key: 'facturacionEmpresa.fechaCorte'},
  {label: 'Tipo de Facturación', key: 'facturacionEmpresa.tipoFacturacion'},
  {
    label: 'Observaciones de facturación',
    key: 'facturacionEmpresa.observaciones',
  },
  {label: 'Nombre', key: 'facturacionEmpresa.nombreContacto'},
  {label: 'Correo', key: 'facturacionEmpresa.correoContacto'},
  {label: 'Teléfono', key: 'facturacionEmpresa.telefonoContacto'},
  {label: 'Activar / Desactivar Empresa', key: 'estaActiva'},
  {label: 'Motivo inactivación', key: 'motivoDesactivacion'},
  {label: 'Ejecutivo comercial', key: 'ejecutivoComercial'},
  {label: 'Observaciones de la empresa', key: 'observaciones'},
]

export const encabezadosExportarEmpresasHijas = [
  {label: 'Id', key: 'id'},
  {label: 'Nit', key: 'nit'},
  {label: 'Razón Social', key: 'nombre'},
  {label: 'Dirección', key: 'direccion'},
  {label: 'Nombre Empresa Impresiones HC', key: 'nombreEmpresaHC'},
  {label: 'País', key: 'pais'},
  {label: 'Departamento', key: 'departamento'},
  {label: 'Ciudad', key: 'ciudad'},
  {label: 'Teléfono', key: 'telefono'},
  {label: 'Correo envió certificados', key: 'correo'},
  {label: 'Razón Social Factura', key: 'facturacionEmpresa.razonSocial'},
  {label: 'RUT Casilla 53', key: 'facturacionEmpresa.rut53'},
  {label: 'RUT Casilla 54', key: 'facturacionEmpresa.rut54'},
  {label: 'Código Postal', key: 'facturacionEmpresa.codigoPostal'},
  {
    label: 'Actividad económica principal',
    key: 'facturacionEmpresa.actividadEconomica',
  },
  {label: 'Matricula mercantil', key: 'facturacionEmpresa.matriculaMercantil'},
  {
    label: 'Correo para facturación',
    key: 'facturacionEmpresa.correoFacturacion',
  },
  {label: 'Valor contrato', key: 'facturacionEmpresa.valorContrato'},
  {label: 'Condiciones de pago', key: 'facturacionEmpresa.condicionPago'},
  {label: 'Descuento', key: 'facturacionEmpresa.descuento'},
  {label: 'Impuestos Gravables', key: 'facturacionEmpresa.impuesto'},
  {label: 'Dia de corte de facturación', key: 'facturacionEmpresa.fechaCorte'},
  {label: 'Tipo de Facturación', key: 'facturacionEmpresa.tipoFacturacion'},
  {
    label: 'Observaciones de facturación',
    key: 'facturacionEmpresa.observaciones',
  },
  {label: 'Nombre', key: 'facturacionEmpresa.nombreContacto'},
  {label: 'Correo', key: 'facturacionEmpresa.correoContacto'},
  {label: 'Teléfono', key: 'facturacionEmpresa.telefonoContacto'},
  {label: 'Activar / Desactivar Empresa', key: 'estaActiva'},
  {label: 'Motivo inactivación', key: 'motivoDesactivacion'},
  {label: 'Ejecutivo comercial', key: 'ejecutivoComercial'},
  {label: 'Observaciones de la empresa', key: 'observaciones'},
]

export const encabezadosFacturas = [
  {label: 'Id', key: 'id'},
  {label: 'Nombre del documento', key: 'nombreDocumento'},
  {label: 'Sede', key: 'sede'},
  {label: 'Fecha Emisión', key: 'fechaEmision'},
  {label: 'Nombre Tercero', key: 'nombreTercero'},
  {label: 'Valor Total', key: 'valorTotal'},
  {label: 'Estado Emisíon', key: 'estadoEmision'},
  {label: 'Nota Crédito', key: 'notaCredito'},
]

export const encabezadosReporteContabilidad = [
  {label: 'Fecha del pago', key: 'fechaPago'},
  {label: 'Cuenta Bancaria', key: 'cuenta.numeroCuenta'},
  {label: 'Nombre del banco', key: 'cuenta.nombreBanco'},
  {label: 'Ref. Doc o ID Transacción', key: 'referencia'},
  {label: 'Descripción', key: 'descripcion'},
  {label: 'Referencia 2', key: 'segundaReferencia'},
  {label: 'Otro', key: 'otro'},
  {label: 'Estado bancario', key: 'nombre'},
  {label: 'Valor', key: 'valor'},
  {label: 'Estado de facturación', key: 'estadoFacturacion'},
  {label: 'Usuario confirmación', key: 'nombreUsuario'},
  {label: 'Sede', key: 'nombreSede'},
  {label: 'Fecha confirmación', key: 'fechaConfirmacion'},
  {label: 'Email de facturación', key: 'facturacion'},
  {label: 'Nombre de facturación', key: 'razonSocial'},
  {label: 'Documento de facturación', key: 'nit'},
  {label: 'Número de Factura', key: 'numeroFactura'},
  {label: 'Usuario de facturación', key: 'nombreUsuario'},
]

export const encabezadosReporteVerTiqueteras = [
  {label: 'Fecha', key: 'fechaPago'},
  {label: 'Nit - Razón social', key: 'configEmpresa'},
  {label: 'Ciudad', key: 'ciudadNombre'},
  {label: 'Tipo de tiquetera', key: 'nombreTiquetera'},
  {label: 'Cantidad', key: 'cantidad'},
  {label: 'Nº Factura', key: 'numeroFactura'},
  {label: 'Valor', key: 'valor'},
  {label: 'Quien entrega', key: 'responsableEntrega'},
  {label: 'Nº Libreta', key: 'numeroLibreta'},
  {label: 'Consecutivo', key: 'consecutivo'},
  {label: 'Comercial', key: 'comercial'},
]
